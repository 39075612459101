import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { correctOptionColorChange } from '../JsFunctions/optionBackgroundColorControl'
export function Timer(props) {
    return (
        <CountdownCircleTimer
            key={props.uniqueKey}
            isPlaying={props.clockRunning}
            size={250}
            duration={props.timerDuration}
            trailColor="#d9d9d9"
            isSmoothColorTransition={true}
            strokeWidth={25}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
            onComplete={() => {
                props.setShowTimer(false)
                if (props.autoPlaying) {
                    correctOptionColorChange(props.correctOption)
                }
            }}
        >
            {({ remainingTime }) =>
                <>
                    <div className="time-wrapper" onDoubleClick={() => { props.setShowTimer(false) }} >
                        <div className="time">
                            <div className="text">Stay</div>
                            <div className="value" style={{ color: "red" }}>{remainingTime}</div>
                            <div className="text">Tuned</div>
                        </div>
                        {
                            props.autoPlaying ? false : <button style={pauseButtonStyling} onClick={() => { props.setClockRunning(!props.clockRunning) }} >  {
                                props.clockRunning ? "Pause" : "Resume"
                            }   </button>
                        }
                    </div>
                </>
            }
        </CountdownCircleTimer>
    )
}


const pauseButtonStyling = {
    width: "fit-content",
    display: "flex",
    margin: "auto",
    marginTop: "4px",
    border: "0px solid transparent",
    padding: "4px 15px"
}
import HeadHome from "./head.home";
import Section2Home from "./section2.home";
import Section3Home from "./section3.home";
import ClientsHome from "./clients.home";
import Section4Home from "./section4.home";
import ContactHome from "./contact.home";
import ContentHome from "./content.home";

const MainHome=()=>{
    return(
        <>
            <HeadHome/>
          {/*  <Section2Home/> 
            <ContentHome/>
            <Section3Home/>
            <ClientsHome/>
            <Section4Home/>
            <ContactHome/> */}
        </>
    )
}
export default MainHome;

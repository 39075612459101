import {RxCross2} from "react-icons/rx";
import {useEffect, useRef, useState} from "react";
import {AiFillEdit} from "react-icons/ai";

const EditThumbnail=(props)=>{

    const closeBtnRef = useRef(null);
    const [title,setTitle]=useState("");
    const [image,setImage]=useState();
    const [userId,setUserId]=useState("");
    const [pararm,setParam]=useState("");
    const [visibility,setVisibility]=useState();

    useEffect(()=>{
        // debugger
        // console.log(JSON.stringify(props.dataRow))

    },[])


    // set initial values
    useEffect(()=>{
        setTitle(props.dataRow.thumbnail_title)
        setImage(props.dataRow.thumbnail_url)
        setParam(props.dataRow.sorting_params	)
        setUserId(props.dataRow.user_id)
        setVisibility(props.dataRow.visibility)
    },[props.dataRow])

    const handleEdit=(e)=>{
        e.preventDefault();
        alert("update called")
        closeBtnRef.current.click();
    }


    return(
        <>
            <input type="checkbox" id="editModal" className="modal-toggle" />
            <label htmlFor="editModal" className="modal cursor-pointer">
                <label className="modal-box !rounded-[5px] relative" htmlFor="">
                    <div className="card-actions mt-4 mr-[-5px] justify-end">
                        <label ref={closeBtnRef} htmlFor="editModal" className="btn btn-ghost btn-sm mt-[-30px]">
                            <RxCross2/>
                        </label>
                    </div>
                        <form className="" onSubmit={handleEdit}>
                            <p className="text-[25px] mb-4 font-bold ">Edit Thumbnail Details</p>
                            <div className="ml-5 mr-5">
                                <div className="form-control ">
                                    <label className="label">
                                        <span className="label-text c-font1 font-bold">Thumbnail Title</span>
                                    </label>
                                    <input type="text" value={title} placeholder="Thumbnail" className="input w-full c-input c-font1 !rounded-sm text-sm"
                                            onChange={(e)=>(setTitle(e.target.value))} required/>
                                           {/*// value={image} onChange={(e)=>(setImage(e.target.value))} required/>*/}
                                </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text c-font1 font-bold">Thumbnail</span>
                                        </label>
                                        <label className="input-group">
                                            <img src={image} width={100}  className="rounded-lg aspect-video " />
                                            <input type="file" id="thumbnail" className="hidden input w-full c-input c-font1 !rounded-sm text-sm"
                                                   onChange={(e) => setImage(URL.createObjectURL(e.target.files[0]))} />
                                            <AiFillEdit className="text-2xl"/>
                                        </label>
                                    </div>
                                <div className="form-control ">
                                    <label className="label">
                                        <span className="label-text c-font1 font-bold">User Id</span>
                                    </label>
                                    <input type="text" placeholder="Enter user id" className="input w-full c-input c-font1 !rounded-sm text-sm"
                                           value={userId} onChange={(e)=>(setUserId(e.target.value))} required/>
                                </div>
                                <div className="form-control ">
                                    <label className="label">
                                        <span className="label-text c-font1 font-bold">Sorting Param</span>
                                    </label>
                                    <input type="text" placeholder="Enter Sorting Param" className="input w-full c-input c-font1 !rounded-sm text-sm"
                                           value={pararm} onChange={(e)=>(setParam(e.target.value))} required/>
                                </div>
                                <div className="form-control ">
                                    <label className="label">
                                        <span className="label-text c-font1 font-bold">Visibility</span>
                                    </label>
                                    {/*checked={visibility} onChange={(e)=>(setVisibility(e.target.value))}*/}
                                    <input type="checkbox" className="toggle m-1 toggle-success" checked={visibility} onChange={(e)=>(setVisibility(e.target.checked))} />
                                </div>
                                <button type="submit" className="btn mt-2 w-full" >Update</button>
                            </div>
                        </form>
                </label>
            </label>
        </>
    )
}
export default EditThumbnail;

const ConfigComponent=({setContentFontSize, setShowCorrectOption, setDiscussionSpacing, setShowShortAnswer, setShowSolution,setShowQuestions, setShowOptions, setShowBillingual, setShowPreviousYearTag, contentFontSize, showCorrectOption, discussionSpacing, showShortAnswer, showSolution,showQuestions, showOptions, showBillingual, showPreviousYearTag})=>{
    return(
        <>
            <div className="bg-base-200 div-to-hide">
                <section className="hero container max-w-screen-lg  p-10 !w-[700px]">
                    <div className="flex flex-col  lg:flex-row bg-base-100 shadow-xl  !rounded-sm card">
                        <div className="grid m-4 flex-grow  card w-5/6	 !rounded-sm place-items-center">
                            <p className="ml-1 text-2xl font-bold">PDF Configuration</p>
                            <div className="form-control w-full">
                                <label className="label cursor-pointer">
                                    <span className="ml-1 w-full c-font1">Font Size: {contentFontSize}</span>
                                    <input type="range" min="0" max="40" value={contentFontSize} onChange={(e)=>{setContentFontSize([e.target.value])}} className="range range-xs range-primary" />
                                </label>
                            </div>
                            <div className="form-control w-full">
                                <label className="label cursor-pointer">
                                    <span className="ml-1 w-full c-font1">Spacing: {discussionSpacing}</span>
                                    <input type="range" min="0" max="40"  value={discussionSpacing} onChange={(e)=>{setDiscussionSpacing([e.target.value])}} className="range range-xs range-primary" />
                                </label>
                            </div>
                            <div className="form-control w-full">
                                <label className="label cursor-pointer">
                                    <span className="ml-1 flex-1">Answer Bold:</span>
                                    <input type="checkbox" checked={showCorrectOption} onChange={(e)=>{setShowCorrectOption(e.target.checked)}}  className="toggle toggle-warning" />
                                </label>
                            </div>
                        </div>
                        <div className="divider lg:divider-horizontal">and</div>
                        <div className="grid flex-grow  card w-4/5	 !rounded-sm place-items-center">
                            <div className="grid gap-4 c-font1 p-2 text-xs font-bold">
                                <div>
                                    <div className="form-control flex">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" checked={showShortAnswer} onChange={(e)=>{setShowShortAnswer(e.target.checked)}} className="toggle toggle-warning" />
                                            <span className="ml-1 flex-1">Show Answer Widget</span>
                                        </label>
                                    </div>
                                    
                                    <div className="form-control flex">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" checked={!showQuestions} onChange={(e)=>{setShowQuestions(!e.target.checked)}}  className="toggle toggle-warning" />
                                            <span className="ml-1 flex-1">Hide Question</span>
                                        </label>
                                    </div>


                                    <div className="form-control flex">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" checked={!showOptions} onChange={(e)=>{setShowOptions(!e.target.checked)}}  className="toggle toggle-warning" />
                                            <span className="ml-1 flex-1">Hide Option</span>
                                        </label>
                                    </div>

                                    <div className="form-control flex">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" checked={showSolution} onChange={(e)=>{setShowSolution(e.target.checked)}}  className="toggle toggle-warning" />
                                            <span className="ml-1 flex-1">Show Solution</span>
                                        </label>
                                    </div>

                                    

                                    <div className="form-control flex">
                                        <label className="label cursor-pointer">
                                            <input type="radio" value="Hindi" checked={showBillingual==='Hindi'} onChange={(e)=>{setShowBillingual(e.target.value)}}/>
                                            <span className="ml-1 flex-1">Hindi</span>
                                            <input type="radio" value="English" checked={showBillingual==='English'} onChange={(e)=>{setShowBillingual(e.target.value)}}/>
                                            <span className="ml-1 flex-1">English</span>
                                            <input type="radio" value="Bilingual" checked={showBillingual==='Bilingual'} onChange={(e)=>{setShowBillingual(e.target.value)}}/>
                                            <span className="ml-1 flex-1">Bilingual</span>
                                        </label>
                                    </div>

                                    <div className="form-control flex">
                                        <label className="label cursor-pointer">
                                            <input type="checkbox" checked={showPreviousYearTag} onChange={(e)=>{setShowPreviousYearTag(e.target.checked)}}  className="toggle toggle-warning" />
                                            <span className="ml-1 flex-1">Previous Year Tag</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default ConfigComponent;

function Footer() {
    return (
        <>
            {/* <hr style={{ margin: "0px 0px 1px 0px", color: "yellow" }} /> */}
            <div style={{ backgroundColor: "#300454", color: "white" }} id="Footer" contentEditable={true}>
                <p style={{ padding: "2px 6px 2px 16px", fontFamily: "Poppins" }}>Download Utkarsh App from Playstore</p>
            </div>
        </>
    );
}
export default Footer;

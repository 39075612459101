export const actionStyle = {
    fontSize: "40px",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
    borderRadius: "2px",
    margin: "0px 10px",
    cursor: "pointer",
    padding:"4px"
};

export const jumpingBoxStyle = {
    backgroundColor: "#fff",
    border: "none",
    borderRadius: "100px",
    height: "50px",
    width: "50px",
    fontSize: "1.5em",
    margin: "2px 4px",
    color: "rgba(1, 1, 1, 0.5)",
    boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px",
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "Roboto Slab",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: "black",
};

export const timerBoxAllElement = {
    backgroundColor: "#c53232",
    position: "fixed",
    bottom: "70px",
    left: "420px",
    padding: "4px", borderRadius: "6px", fontFamily: "serif",
    fontWeight: "bold",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
};
export const timerBoxAllElementForAutoPlaySuggestion = {
    backgroundColor: "#c53232",
    position: "fixed",
    bottom: "70px",
    left: "650px",
    padding: "4px", borderRadius: "6px", fontFamily: "serif",
    fontWeight: "bold",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
};

export const timerSecondBox = {
    fontSize: "20px",
    boxShadow: "white 0px 0.0625em 0.0625em, white 0px 0.125em 0.5em, white 0px 0px 0px 1px inset",
    margin: "4px",
    padding: "4px", borderRadius: "4px",
    color: "white",
    cursor: "pointer"
};


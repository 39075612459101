import { jumpingBoxStyle } from "../Styles/style_variables";
import { initMathJax } from '../JsFunctions/initMathJax'
import {RxCross2} from "react-icons/rx";
import { useRef} from "react";
export function JumpQuestionModal(props) {

    const closeBtnRef = useRef(null)


        // closeBtnRef.current.click();



    return (
        <>


        <input type="checkbox" id="questionJumpModal" className="modal-toggle" />
        <label htmlFor="questionJumpModal" className="modal cursor-pointer">
        <label className="modal-box  !rounded-sm relative" htmlFor="">
            <div className="card-actions mt-4 mb-2 justify-end">
                            <label ref={closeBtnRef} htmlFor="questionJumpModal" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
            </div>
            {
                    props.questions.length == 0 ? false :
                        <div className="modal-body">
                            <div style={{ display: "flex", flexWrap: "wrap", maxHeight: "50vh", overflowY: "scroll", justifyContent: "center" }}>
                                {(() => {
                                    let jumpingNumbers = [];
                                    for (let i = 0; i <= props.questions.length - 1; i++) {

                                        jumpingNumbers.push(<div data-bs-dismiss="modal" onClick={() => {
                                            props.setQuestionNo(i)
                                            initMathJax();
                                        }} key={i} style={jumpingBoxStyle}>{props.questions[i].question_no}</div >);
                                    }
                                    return jumpingNumbers;
                                })()}
                            </div>
                        </div>
                }
        </label>
        </label>

         
        </>
    )
}

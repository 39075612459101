import parse from "html-react-parser";
export function Questions(props) {
  var question_hin = props.question_hin.replaceAll(`\\′`, `'`);
  let previous_of = props.previous_of;
  if (previous_of == "") {
    previous_of = "Expected";
  }
  let showPreviousOf = props.showPreviousOf;
  if (props.question_eng != null) {
    var question_eng = props.question_eng.replaceAll(`\\′`, `'`);
  }
  var question_no = props.question_no;
  return (
    <div
      className="PPT-Questions w3-animate-right"
      style={{ backgroundColor: props.questionBackgroundColor }}
    >
      <div
        className="single_question"
        style={{ color: props.questionTextColor }}
      >
        <p
          id="id-for-getting-question-font-size"
          className="indexPPT"
          style={{
            fontSize: props.questionFontSize / 1.5 + "px",
            backgroundColor: props.questionTextColor,
            color: props.questionBackgroundColor,
            borderRadius: "150%",
            padding: "0px 12px",
          }}
        >
          {question_no == "" || question_no == undefined || question_no == null
            ? false
            : question_no + "."}
        </p>
        <div style={{ fontSize: props.questionFontSize + "px" }} className="customQuestionMathAJAX ">
          {props.bilingualContent
            ? question_hin == question_eng || props.question_eng == undefined
              ? parse(question_hin)
              : parse(
                  question_hin +
                    "<hr style='margin:2px;color:yellow;height:3px;border-radius:10px' >" +
                    question_eng
                )
            : parse(question_hin)}
        </div>
      </div>
      {showPreviousOf === true && previous_of !== "" ? (
        <div style={previousOfBoxStyling}>
          <img
            style={{ borderRadius: "100px" }}
            height={"36px"}
            src={process.env.PUBLIC_URL + "/verified.gif"}
            alt=""
          />{" "}
          {previous_of}
        </div>
      ) : (
        false
      )}
    </div>
  );
}

const previousOfBoxStyling = {
  backgroundColor: "yellow",
  float: "right",
  padding: "0px 16px",
  borderRadius: "100px",
  fontFamily: "serif",
  fontSize: "28px",
  marginTop: "4px",
  border: "1px solid white",
  fontStyle: "italic",
  display: "flex",
};

export function questionCurrentFontSize() {
  var fontSize = window.getComputedStyle(
    document.getElementById("id-for-getting-question-font-size")
  ).fontSize;
  console.log(fontSize);
  return fontSize;
}

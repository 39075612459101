import { useLocation, useNavigate } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import LoadingComponent from "../../components/loading.component";
import {toast} from "react-toastify";
import {initMathJax} from "../../components/home/OnBoard/JsFunctions/initMathJax";
import FileUploadComponent from "../../components/fileUpload.component";
import {RxCross2} from "react-icons/rx";
import axios from '../../api/axios';


const EditQuestion = () => {
  const location = useLocation();

  let configData = location.state?.data ?? {};
  // console.log(configData)

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const editor = useRef(null);

  const [questions, setQuestions] = useState([]);

  const [questionsCopy, setQuestionsCopy] = useState([]);
  const [questionsUpt, setQuestionsUpt] = useState([]);

  const [count, setCount] = useState(0); // current index
  const [currentTab, setCurrentTab] = useState(1);

  const [activeTab, setActiveTab] = useState(1);

  const [lang, setLang] = useState("eng");
  const [editorVal, setEditorVal] = useState("Welcome");



  let resData;
  const getData = async () => {
    setIsLoading(true);
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        set_id: configData.set_id,
        set_password: configData.set_password,
        endpoint: "",
      }),
    };
    const response = await fetch(`https://teachbackendapi.utkarshpublications.com/app-to-web`,
        options
    );
    resData = await response.json();
    setQuestions(resData.data);
    setQuestionsCopy(resData.data);
    setTimeout(() => 10);
    setEditorVal(resData.data[count][`question_${lang}`]); // init val
    // console.log(editorVal)s
    makeItActive(resData.data[count][`answer`])
    initMathJax()
    setIsLoading(false);

    if (resData["status"] === 404) {
      const Toast = Swal.mixin(
          {
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          },
          setIsLoading(false)
      );

      Toast.fire({
        icon: "error",
        title: "No data found",
      });
      return navigate(location.state?.path || "/"); //prev
      // setRecordId(resData.data[count].record_id)
    }
  };

  const handleTab = (tabIndex) => {

    if (tabIndex === currentTab) {
      return;
    }
    setCurrentTab(tabIndex);
    setActiveTab(tabIndex);
    let editorVal = "";
    if (tabIndex === 1) {
      editorVal = questions[count][`question_${lang}`];
    } else if (tabIndex === 7) {
      editorVal=  questions[count][`solution_${lang}`];
    } else if (tabIndex === 8) {
      editorVal= questions[count].answer;
    } else {
      editorVal=  questions[count][`option${tabIndex - 1}_${lang}`];
    }
    setEditorVal(editorVal);
    initMathJax()
  };

  const handleNext = () => {
    setEditorVal(questions[count][`option${activeTab - 1}_${lang}`]);
    setCount(count + 1, () => {
      setCount(questions[count].question_no);
    });
    if (activeTab == 1) {
      setActiveTab(1);
      setEditorVal(questions[count][`question_${lang}`]);
    } else if (activeTab == 7) {
      setActiveTab(7);
      setEditorVal(questions[count][`solution_${lang}`]);
    } else if (activeTab == 8) {
      setActiveTab(8);
      setEditorVal(questions[count][`answer`]);
    }
    initMathJax()
  };
  const handlePrev = () => {
    const prevCount = count - 1;
    prevCount >= 0 && setCount(prevCount);
    setCount(prevCount);
    if (activeTab === 1) {
      setEditorVal(questions[prevCount][`question_${lang}`]);
    } else if (activeTab === 7) {
      setEditorVal(questions[prevCount][`solution_${lang}`]);
    } else if (activeTab === 8) {
      setEditorVal(questions[prevCount][`answer`]);
    } else {
      setEditorVal(questions[prevCount][`option${activeTab - 1}_${lang}`]);
    }
    initMathJax()
  };
  const handleLang = () => {
    // setEditorVal(questions[count][`option${activeTab-1}_${lang}`])
    if (questions.length > 0 && activeTab == 1) {
      setEditorVal(questions[count][`question_${lang}`]);
    } else if (activeTab == 7) {
      setEditorVal(questions[count][`solution_${lang}`]);
    } else if (activeTab == 8) {
      setEditorVal(questions[count][`answer`]);
    } else {
      if (questions.length > 0)
        setEditorVal(questions[count][`option${activeTab - 1}_${lang}`]);
    }
    initMathJax()
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      setEditorVal(
          activeTab === 1 ? questions[count][`question_${lang}`] :
              activeTab === 7 ? questions[count][`solution_${lang}`] :
                  activeTab === 8 ? questions[count][`answer`] :
                      questions[count][`option${activeTab - 1}_${lang}`]
      );
    }
  }, [lang, count,activeTab,currentTab]);

  const makeItActive=(key)=>{
    if(key=="A"){
      setRadioChecked(1)
    }
    else if(key=="B"){
      setRadioChecked(2)
    }else if(key=="C"){
      setRadioChecked(3)
    }else if(key=="D"){
      setRadioChecked(4)
    }else if(key=="E"){
      setRadioChecked(5)
    }
  }

  const [recordId,setRecordId]=useState();
  const [colToUpdate,setColToUpdate]=useState();
  const [valueToUpdate,setValueToUpdate]=useState();

  const updateQuestion = (updatedContent, id) => {

    let key;
    if(activeTab===1 && currentTab===1){
      key = `question_${lang}`
    }
    else if (activeTab === 7 && currentTab===7) {
      key = `solution_${lang}`;
    } else if (activeTab === 8 && currentTab===8) {
      key = `answer`;
    }
    else {
      key = `option${activeTab-1}_${lang}`;
    }

    setRecordId(questions[id].record_id)
    setColToUpdate(key)
    setValueToUpdate(updatedContent)

    const subarrayIndex = questions.findIndex(question => question.record_id === questions[id].record_id);


    if (subarrayIndex !== -1) {
      const updatedSubarray = { ...questions[subarrayIndex] };

      if (!updatedSubarray[key]) {
        updatedSubarray[key] = {};
      }

      updatedSubarray[key] = updatedContent;

      const updatedQuestions = [      ...questions.slice(0, subarrayIndex),      updatedSubarray,      ...questions.slice(subarrayIndex + 1)    ];

      setQuestionsUpt(updatedQuestions)
      // setQuestions(updatedQuestions);
      // console.log("u",questions)
    }
  };

  const [radioCheck,setRadioChecked]=useState("null");

  const handleUpdate=async () => {
    initMathJax()
    const token=localStorage.getItem('token')
    const liveTestCreatingToast = toast.warning('Updating...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        recordId:recordId,
        columnToUpdate:colToUpdate,
        valueToUpdate:valueToUpdate

      }),
    };
    const response = await fetch(`https://teachbackendapi.utkarshpublications.com/updateIndividualQuestionToDB`,
        options
    );
    let resData = await response.json();
    // console.log(resData);
    if (resData["status"] === 200) {
      setQuestions(questionsUpt)
      setQuestionsCopy(questionsUpt)
      toast.update(liveTestCreatingToast, { render: "Updated Successfully", autoClose: 3000,
        type: "success", isLoading: false });
    }
    else  if (resData["status"] === 400) {
      setQuestions(questionsUpt)
      setQuestionsCopy(questionsUpt)
      toast.update(liveTestCreatingToast, { render: "Nothing Updated", autoClose: 3000,
        type: "warning", isLoading: false });
    }
  }

  const closeBtnRef=useRef();
  const [find,setFind]=useState();
  const [findData,setFindData]=useState([]);
  const [findLoader,setFindLoader]=useState(true);

  const handleFind=async (e) => {
    e.preventDefault()
    const token=localStorage.getItem('token')
    const liveTestCreatingToast = toast.warning('Searching...', {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
      hideProgressBar: true,
      isLoading: true
    });
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token: token,
        find:find
      }),
    };
    const response = await fetch(
        `https://637c-2401-4900-1c37-b679-95c5-d5ff-6391-d7ed.ngrok-free.app/getRelatedQuestions`,
        options
    );
    let resData = await response.json();
    setFindData(resData)
    if (resData.length>0) {
      setQuestions(questionsUpt)
      setQuestionsCopy(questionsUpt)
      toast.update(liveTestCreatingToast, { render: "Searched Successfully", autoClose: 3000,
        type: "success", isLoading: false });
      setFindLoader(false)
    }
    else  {
      setQuestions(questionsUpt)
      setQuestionsCopy(questionsUpt)
      toast.update(liveTestCreatingToast, { render: "Search Failed", autoClose: 3000,
        type: "warning", isLoading: false });
    }
  }

  useEffect(()=>{
    if(findLoader && find==""){
      setFindData([])
      setFind("")
    }
  },[])

  return (
      <>

        {isLoading ? (
            <LoadingComponent />
        ) : (
            <div>

              <div className="bg-base-200 pt-4 p-1 new-c-font c-size">

                <div className={'overflow-y-auto w-screen pb-1'}>
                  <div className={'flex  gap-2 ml-1 mr-1 border-none  !rounded-[2px] '}>
                    {
                      questions.map((item,key)=>(
                          <button onClick={()=>{
                            initMathJax()
                            setCount(key);
                            makeItActive(questions[key]['answer'])
                          }} className={`btn btn-circle border-transparent  bg-gray-400 ${count==key?'bg-gray-700':''}`}>{key +1 }</button>
                      ))
                    }
                  </div>
                </div>

                <div className='grid grid-cols-2 gap-2 m-2 '>
                  <div>
                    {/*className="items-center1" style={{alignItems:"center"}}*/}
                    {/*{*/}
                    {/*  questionsCopy[count][`question_${lang}`] .match(imgRegex)? <div className="inline-flex items-center"  dangerouslySetInnerHTML={{ __html: questionsCopy[count][`question_${lang}`] }}></div>:<div   dangerouslySetInnerHTML={{ __html: questionsCopy[count][`question_${lang}`] }}></div>*/}
                    {/*}*/}
                    <div  className="customQuestionMathAJAX" dangerouslySetInnerHTML={{ __html: questionsCopy[count][`question_${lang}`] }}></div>
                    {
                      [1,2,3,4,5].map((item,key)=>(
                          <div key={key} className={`alert alert-sm pt-2 pb-1 flex  c-border rounded-[2px] justify-start mb-2 !shadow-lg block ${radioCheck==key+1?"alert-success":""} ${questionsCopy[count][`option${key+1}_${lang}`].length==0?"hidden":""}`}>
                            {/*<input type="radio"  className={`!radio  c-border !radio-accent`} checked={radioCheck==key+1?true:false} />*/}
                            <p className="italic opacity-50">{key+1} :</p>
                            <div dangerouslySetInnerHTML={{ __html: questionsCopy[count][`option${key+1}_${lang}`] }}></div>
                          </div>
                      ))
                    }
                  </div>
                  {
                    questionsCopy[count][`solution_${lang}`].length==0  ?<div className={` block ${questions[count][`solution_${lang}`].length==0?"hidden":""}`}>
                      <p className=" new-c-font">Explanation:</p>
                      <div className={`p-4 c-border !rounded-[2px]  mb-2 !shadow-lg ` } dangerouslySetInnerHTML={{ __html: questions[count][`solution_${lang}`] }}></div>
                    </div>:<div className={`block ${questionsCopy[count][`solution_${lang}`].length==0?"hidden":""}`}>
                      <p className="text-sm c-font-bold ">Explanation</p>
                      <div className={`p-4 !overflow-auto h-56 c-border rounded-[2px] mb-2 !shadow-lg ` } dangerouslySetInnerHTML={{ __html: questionsCopy[count][`solution_${lang}`] }}></div>
                    </div>
                  }
                </div>

                <div className="grid mt-1 grid-cols-3">
                  <div className="col-span-2 gap-2">
                    <select
                        className="select w-auto mt-2 max-sm:w-32 mb-4 select-sm"
                        value={lang}
                        onChange={(e) => {
                          setLang(e.target.value);
                          handleLang();
                        }}
                    >
                      <option disabled selected>
                        Select Language (English)
                      </option>
                      <option value="hin">Hindi</option>
                      <option value="eng">English</option>
                    </select>
                  </div>
                  <div className="justify-self-end ">
                    <label htmlFor="findModal" className="btn max-sm:btn-sm mr-2">Test</label>
                    <div className="btn-group">
                      {count == 0 ? (
                          <button
                              className="btn mr-2 btn-outline gap-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] max-sm:btn-sm"
                              disabled
                          >
                            Previous
                          </button>
                      ) : (
                          <button
                              onClick={handlePrev}
                              className="btn btn-outline mr-2 gap-2 pl-10 pr-10 c-font1  capitalize max-sm:btn-sm font-bold rounded-[5px]"
                          >
                            Previous
                          </button>
                      )}
                      {count == questions.length - 1 ? (
                          <button
                              className="btn  mr-2 gap-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] max-sm:btn-sm"
                              disabled
                          >
                            Next
                          </button>
                      ) : (
                          <button
                              onClick={handleNext}
                              className="btn btn-outline btn-outline mr-2 gap-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] max-sm:btn-sm"
                          >
                            Next
                          </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="tabs">
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 1 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(1)}
                  >
                    Question
                  </a>
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 2 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(2)}
                  >
                    Option 1
                  </a>
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 3 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(3)}
                  >
                    Option 2
                  </a>
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 4 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(4)}
                  >
                    Option 3
                  </a>
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 5 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(5)}
                  >
                    Option 4
                  </a>
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 6 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(6)}
                  >
                    Option 5
                  </a>
                  <a
                      className={`tab tab-lifted ${
                          activeTab === 7 ? "tab-active font-bold" : ""
                      }`}
                      onClick={() => handleTab(7)}
                  >
                    Solution
                  </a>
                  {/*<a*/}
                  {/*    className={`tab tab-lifted ${*/}
                  {/*        activeTab === 8 ? "tab-active font-bold" : ""*/}
                  {/*    }`}*/}
                  {/*    onClick={() => handleTab(8)}*/}
                  {/*>*/}
                  {/*  Answer*/}
                  {/*</a>*/}
                </div>

                <div className="flex max-sm:grid">
                  <div className="flex-1">
                    <JoditEditor
                        ref={editor}
                        value={editorVal}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent =>   updateQuestion(newContent, count)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {
                          updateQuestion(newContent, count)
                        }}
                    />
                  </div>
                  <div className="flex-none text-right mr-2">
                    <FileUploadComponent />
                    <button onClick={handleUpdate}  className="btn  w-full justify-self-end m-2 z-20  gap-2 pl-10 pr-10 c-font1 capitalize font-bold rounded-[5px] max-sm:btn-sm">Save</button>
                  </div>
                </div>
                <div className="flex justify-end">
                </div>

              </div>
            </div>
        )}


      {/*  modal*/}

        <input type="checkbox" id="findModal" className="modal-toggle" />
        <label htmlFor="findModal" className="modal w-screen cursor-pointer">
          <label className="modal-box   w-11/12 max-w-5xl !rounded-[2px] relative" htmlFor="">
            <p className="text-2xl font-bold c-font1">Find</p>
            <div className="card-actions  justify-end">
              <label ref={closeBtnRef} htmlFor="findModal" className="btn btn-ghost btn-sm mt-[-30px]" onClick={()=>{  setFindData([]); setFindLoader(true)}}>
                <RxCross2/>
              </label>
            </div>
            <form className="text-center" onSubmit={handleFind}>
              <div className="form-control ">
                <label className="label">
                  <span className="label-text">Label</span>
                </label>
                <input type="text" placeholder="Enter search query" className="input rounded-[5px] input-bordered text-sm   !rounded-sm c-font1"
                       value={find} onChange={(e)=>{setFind(e.target.value);}}/>
              </div>
              <button className="btn !rounded-[2px]  mt-4 w-full">Find</button>

              {!findLoader?<p className="text-left">Search Result For: {find}</p>:null}
              {!findLoader?<div className="mt-2 h-[200px] overflow-y-auto !text-left">
                <ul>
                    {
                      findData.map((item,key)=>(
                          <div>
                            <li dangerouslySetInnerHTML={{ __html: item.question_hin }} className="p-1"></li>
                            <hr/>
                          </div>
                      ))
                    }
                  </ul>
              </div>:null}

            </form>
          </label>
        </label>


      </>
  );
};
export default EditQuestion;


import Swal from "sweetalert2";
import axios from '../../../../api/axios';

export function updatePositionAPI(questionSource,id, position) {
    updateIndividualQuestionToDB(questionSource,id, "ppt_position_config", position)
}


async function updateIndividualQuestionToDB(questionSource,record_id, column_to_update, value_to_update) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    await axios({
        method: "POST",
        data: {
            token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3Nzc3NzcsImxvZ29fdXJsIjoiYmxhbmsgbG9nbyIsImluc3RpdHV0ZV9uYW1lIjoiVGVsZWdyYW0gU3BlY2lhbCIsInRlYWNoZXJfbmFtZSI6IkF5dXNoIiwicGhvbmVfbm8iOjc5OTIyODY3NzEsIndoYXRzYXBwX25vIjo3OTkyMjg2NzcxLCJyb2xlIjoxLCJzdGF0dXMiOjIwMCwibG9nZ2VkX2luX2ZsYWciOjg1fQ.IX7cK24h3CSsuQai44fc9sGwJAg77MWXNdhFKQ9KppU",
            recordId: record_id,
            columnToUpdate: column_to_update,
            valueToUpdate: value_to_update,
            questionSource: questionSource
        },
        url: "https://teachbackendapi.utkarshpublications.com/updatePPTPositionConfig",
    }).then(function (response) {
        let apiData = response.data;
        if (apiData['status'] == 200) {
            Toast.fire({
                icon: 'success',
                title: 'Update success'
            })
        } else if (apiData['status'] == 500) {
            Toast.fire({
                icon: 'error',
                title: 'Position not Updated'
            })
        }
    }).catch(function (error) {
        console.log(error);
    });
}
import phn from "../../assets/home/phn-ss.png"
const HeadHome=()=>{

    return(
        <>
            <div className="hero min-h-screen " style={{color:"white"}}>
                <div className="hero-content  flex-col lg:flex-row-reverse">
                    <img className="w-[28%]	max-sm:w-auto max-sm:w-1/2" src={phn}  />
                    <div className="mt-[-200px] ml-20 max-sm:mt-0 max-sm:ml-0 ">
                        <h1 className="text-6xl font-bold max-sm:text-4xl max-sm:text-center">PrasnKOSH App</h1>
                        <h3 className="text-2xl mt-4 font-bold max-sm:text-center">empowering Teachers/Creators</h3>
                        <div className="grid max-sm:justify-items-center ">
                            <div className="card mt-4 max-sm:w-full border border-inherit rounded-[5px] w-4/5">
                                <div className="card-body p-2  p-4 text-xl">
                                    <p className="py-6 c-font1">PrasnKOSH is an application developed by Utkarsh. which eliminates most of the common problems faced while preparing content by the traditional way. It also enhances the learning and teaching experience on both student and teacher end respectivelly by adding some cool features. .</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default HeadHome;

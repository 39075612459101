import Lottie from "lottie-react";
import anim from "../assets/98089-loader.json"
const LoadingComponent=()=>{

    return(
        <>
            <div className="hero min-h-screen bg-base-200">
                <div className="hero-content flex-col lg:flex-row">
                        {/*<div className="btn-group ">*/}
                        {/*    <img src="https://play-lh.googleusercontent.com/RDQgMpE4awGPSncd7-GAv7sE1iie1AItGmk9s11D9XdnctzlccmyvJyYVzI_3rzsBfM" className="w-12 rounded-full shadow-xl"/>*/}
                        {/*    <p className="!font-bold place-self-center ml-1 loading ">Loading..</p>*/}
                        {/*</div>*/}
                    <Lottie animationData={anim} className="w-96" />

                </div>
            </div>
        </>
    )
}
export default LoadingComponent;

import { useState, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";

const AccessPdfHome = () => {
  const [id, setId] = useState();
  const [psw, setPsw] = useState();
  const [endpoint, setEndpoint] = useState();
  const closeBtnRef = useRef(null)

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const accessPdf = async (e) => {
    e.preventDefault();
    closeBtnRef.current.click();
    navigate('/pdf', { state: {data:{ set_id: id, set_password: psw, endpoint: endpoint }, path:location.pathname}});
  };

  return (
    <>
      <input type="checkbox" id="accessPdf" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box !rounded-sm">
          <form className="text-center" onSubmit={accessPdf}>
            <p className="text-2xl c-font1 font-bold">Access PDF</p>
            <div className="card-actions justify-end">
              <label
                ref={closeBtnRef}
                htmlFor="accessPdf"
                className="btn btn-ghost btn-sm mt-[-30px]"
              >
                <RxCross2 />
              </label>
            </div>
            <input
              type="text"
              placeholder="Set Id"
              className="input rounded-[5px] input-bordered text-sm  w-full m-2 !rounded-sm c-font1"
              value={id}
              onChange={(e) => setId(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              className="input input-bordered text-sm rounded-[5px] w-full m-2 !rounded-sm c-font1"
              value={psw}
              onChange={(e) => setPsw(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="end point... (optional)"
              className="input input-bordered text-sm rounded-[5px] w-full m-2 !rounded-sm c-font1"
              value={endpoint}
              onChange={(e) => setEndpoint(e.target.value)}
            />
            {

              !isLoading? <button type="submit"className="btn w-full m-2 !rounded-sm c-font1 normal-case">
                Submit
              </button>:<button type="submit" className="btn w-full m-2 !rounded-sm c-font1 normal-case loading btn-disabled" ></button>
            }
          </form>
        </div>
      </div>
    </>
  );
};
export default AccessPdfHome;

import SignOutComponent from "./signOut.component";
import {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AccessPdfHome from "./home/accessPdf.home";
import GeneratePdfHome from "./home/generatePdf.home";

const NavbarComponent=(props)=>{

    const [authStatus,setAuthStatus]=useState();
    const token=localStorage.getItem('token');
    const navigate = useNavigate();

    const closeRef=useRef()
    const auth=(authStatus)=>{
        setAuthStatus(authStatus)
    } // for navbar states

    let config = JSON.parse(localStorage.getItem('teacher')) || {};

    const location = useLocation();

    const [setIdUtkarsh,setSetIdUtkarsh]=useState()
    const navigateToPageUtk = async (e, toPage = "/", data) => {
        e.target.classList.add("loading");
        e.target.style.minWidth = "137px";

        navigate(toPage, { state: {data:data,  path:location.pathname}});

        closeRef.current.click()
        setSetIdUtkarsh("")
        e.target.classList.remove("loading");
    };

    return(
        <>

            <AccessPdfHome/>
            <GeneratePdfHome/>

            {
                location.pathname=="/ppt"?null:
                    <div className="navbar bg-neutral shadow-2xl div-to-hide" style={{color: "white"}}>
                        <div className="navbar-start !w-[40%] flex max-sm:w-96">
                            <div className="flex cursor-pointer" onClick={() => token ? navigate('/dashboard') : navigate('/')}>
                                <div
                                    className="w-8 rounded-full ring ring-[white] ring-offset-primary ring-offset-1 mr-2">
                                    <img
                                        src={config.logo_url || "images/logo.png"}
                                        className="rounded-full bg-white"/>
                                </div>
                                <Link to="/" className="!font-bold !text-[white] place-self-center text-xl ml-1 max-sm:text-lg">{config.institute_name || "PrasnKOSH for Teachers"}</Link>
                            </div>
                            {/*<Link to="/editor"*/}
                            {/*      className="btn btn-ghost  border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 max-sm:invisible">Editor*/}
                            {/*</Link>*/}
                            { !token?null:  <Link to="/editor" className="max-sm:invisible md:visible lg:visible sm:invisible place-self-center pl-10 pr-2 text-xl ml-1 max-sm:text-lg max-sm:hidden !text-[white]">Editor</Link>}
                            { !token?null:  <Link to="/thumbnails" className="max-sm:invisible md:visible lg:visible sm:invisible place-self-center pl-2 pr-2 text-xl ml-1 max-sm:text-lg max-sm:hidden !text-[white]">Thumbnails</Link>}
                            { !token?null:  <a href="https://form.utkarsh.com/uploads/2023/prashnkosh/user.php" className="max-sm:invisible md:visible lg:visible sm:invisible place-self-center pl-2 pr-2 text-xl ml-1 max-sm:text-lg max-sm:hidden !text-[white]">Users</a>}

                        </div>

                        <div className="navbar-end !w-[70%] max-sm:w-10">

                            {
                                location.pathname == "/login" ? null :
                                    <div className="mr-[-50px] hidden md:block sm:hidden">
                                        <label htmlFor="accessPdf"
                                               className="hidden btn  btn-primary btn-outline bg-white border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 max-sm:invisible">Access
                                            PDF</label>
                                        <label htmlFor="genPdf"
                                               className="hidden btn btn-primary pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 max-sm:invisible">Access
                                            PPT</label>
                                        {token?
                                        <label htmlFor="utkarshModal" className="btn btn-warning border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2">Utkarsh Special</label>
                                        :""}
                                        {(token && location.pathname != "/dashboard") && <button
                                            className="btn btn-accent pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 "
                                            onClick={() => navigate('/dashboard')}>Dashboard</button>}
                                    </div>
                            }

                            {/*mobile view*/}
                            {
                                location.pathname == "/login" ? null :
                                    <div className="dropdown dropdown-end  bg-neutral max-sm:visible invisible">
                                        <label tabIndex={0} className="btn btn-ghost btn-circle">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                      d="M4 6h16M4 12h16M4 18h7"/>
                                            </svg>
                                        </label>
                                        <ul tabIndex={0}
                                            className="menu menu-compact dropdown-content mt-5 p-2 shadow bg-neutral rounded-box w-52 mr-[-65px]">

                                            <label htmlFor="accessPdf"
                                                   className="btn btn-primary btn-outline bg-white border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 ">Access
                                                PDF</label>
                                            <label htmlFor="genPdf" className="btn btn-primary border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 ">
                                                Access
                                                PPT</label>
                                            <label htmlFor="utkarshModal" className="btn btn-warning border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2">Utkarsh Special</label>

                                            {(token && location.pathname != "/dashboard") && <Link to="/dashboard"
                                                                                                   className="btn btn-primary pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 ">Dashboard</Link>}
                                            {!token?null:<Link to="/editor" className="btn btn-primary border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 ">
                                                Editor
                                            </Link>}
                                            {!token?null:<Link to="/thumbnails" className="btn btn-primary border-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px] m-2 ">
                                                Thumbnails
                                            </Link>}
                                        </ul>
                                    </div>
                            }


                            {
                                !token ? <Link to="/login"
                                               className="btn  pl-10 pr-10 c-font1 btn-outline text-white capitalize font-bold rounded-[5px] m-2">Login</Link> :
                                    <div className="dropdown dropdown-end c-font1">
                                        <label tabIndex={0} className="btn btn-ghost btn-circle avatar m-2">
                                            <div
                                                className="w-8 rounded-full ring ring-[white] ring-offset-primary ring-offset-1 mr-2">
                                                <img
                                                    src={config.logo_url || "https://image.bigbooster.in/e2e217b9-ddd7-4ef8-8ceb-f01125c3d482.png"}
                                                    className="rounded-full bg-white"/>
                                            </div>
                                        </label>
                                        <ul tabIndex={0}
                                            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-white rounded-box w-52 "
                                            style={{color: "black"}}>
                                            <li>
                                                <Link to="/"
                                                      className="justify-between hover:bg-neutral hover:text-white">
                                                    Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/profile"
                                                      className="justify-between hover:bg-neutral hover:text-white">
                                                    Profile
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/dashboard"
                                                      className="justify-between hover:bg-neutral hover:text-white">
                                                    Dashboard
                                                </Link>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <Link to="/dashboard/internal"*/}
                                            {/*          className="justify-between hover:bg-neutral hover:text-white">*/}
                                            {/*        Internal Dashboard*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            {/*<li><Link className="hover:bg-neutral hover:text-white" to="/dashboard/setting">Settings</Link></li>*/}
                                            <li><SignOutComponent className="hover:bg-neutral hover:text-white"
                                                                  auth={auth}/></li>
                                        </ul>
                                    </div>
                            }
                        </div>
                    </div>
            }


            {/*    profile*/}


            {/*    modal for utkarsh classes*/}

            <input type="checkbox" id="utkarshModal" className="modal-toggle" />
            <label htmlFor="utkarshModal" ref={closeRef} className="modal cursor-pointer">
                <label className="modal-box relative  !rounded-sm" htmlFor="">
                    <h4 className="text-center text-2xl">Utkarsh Special</h4>
                    <input
                        type="text"
                        placeholder="Enter Set Id"
                        className="input input-bordered text-sm rounded-[5px] w-full mt-2  !rounded-sm c-font1"
                        value={setIdUtkarsh}
                        onChange={(e) => setSetIdUtkarsh(e.target.value)}
                        required
                    />
                    <div className="grid  mt-4 grid-cols-3 gap-2 place-content-stretch">
                        <button className="btn btn-outline btn-primary c-font1  capitalize font-bold rounded-[5px]  truncate"
                                onClick={(e)=>navigateToPageUtk(e, "/pdf", setIdUtkarsh)}
                        >
                            Access PDF
                        </button>
                        <button className="btn btn-primary c-font1 capitalize font-bold rounded-[5px]  truncate"
                                onClick={(e)=>navigateToPageUtk(e, "/ppt", setIdUtkarsh)}
                        >
                            Access PPT
                        </button>
                        <a href={"https://form.utkarsh.com/uploads/2023/question-ppt.php?type=utkset&setid="+setIdUtkarsh+"&set_password="} target="_blank">
                            <button className="btn btn-warning c-font1 capitalize font-bold rounded-[5px]  truncate">
                                Pannel PDF
                            </button>
                        </a>
                    </div>
                </label>
            </label>

        </>
    )
}
export default NavbarComponent;

import { useEffect, useState } from "react";
import LoadingComponent from "./loading.component";
import Lottie from "lottie-react";
import anim from "../assets/no-data-found-404.json";
import {AiFillDelete, AiFillEdit, AiOutlinePlus} from "react-icons/ai";
import EditThumbnail from "./home/Thumbnail/edit.thumbnail";
import Swal from "sweetalert2";
import {RxCross2} from "react-icons/rx";
import AddThumbnail from "./home/Thumbnail/add.thumbnail";
import axios from '../api/axios';

const ThumbnailComponent = () => {

    const [dataRows, setDataRows] = useState([]);
    const [noDataFound, setNoDataFound] = useState(false);
    const token=localStorage.getItem('token');
    const [modalData, setModalData] = useState(false);

    const loadImageData = async () => {
        if (dataRows.length > 0) return
        const response = await fetch(`https://teachbackendapi.utkarshpublications.com/myThumbnails?token=${token}`,
        );
        const resData = await response.json();
        setDataRows(resData["data"]);

    }
    const handleEdit=(id)=>{

        setModalData(dataRows[id])
        return <label htmlFor="editModal"/> &&  <EditThumbnail show={true} dataRow={dataRows[id]} />
    }
    const removeUser=(id)=>{
        Swal.fire(
            {title:'Are you sure to delete?',
                icon:'error',}
        )
    }

    useEffect(()=>{
        loadImageData();
    },[])

    if(noDataFound) return     <div className="!bg-base-100" ><Lottie animationData={anim} className="w-full max-w-sm m-auto" />  </div>
    return (
        <>

            {/*{visibility && }*/}


            <div className="bg-base-100 p-4 justify-end text-right">
                <label htmlFor="addThumbnailModal"  className="btn btn-outline gap-2 pl-10 pr-10 c-font1  capitalize font-bold rounded-[5px]">
                    <AiOutlinePlus className="text-xl"/> Add Thumbnail
                </label>
            </div>

            <div className="bg-base-100  overflow-x-auto">
                <table className="table w-full">
                    {/* head */}
                    <thead>
                    { dataRows.length != 0 &&<tr>
                        <th>#</th>
                        <th>Thumbnail Title</th>
                        <th>Thumbnail Image</th>
                        <th>User Id</th>
                        <th>Sorting Params</th>
                        <th>Visibility</th>
                        <th>Actions</th>
                    </tr>}
                    </thead>
                    <tbody>
                    {dataRows.length == 0 && <LoadingComponent />}
                    {
                        dataRows.map((row, index) => {
                            return (<tr>
                                <th>{ index + 1 }</th>
                                <td>{row.thumbnail_title}</td>
                                <td><img src={row.thumbnail_url} width={100} className="rounded-lg aspect-video" /></td>
                                <td>{row.user_id}</td>
                                <td>{row.sorting_params}</td>
                                <td><input type="checkbox" className="toggle m-1 toggle-success" checked={row.visibility == 1 ? true : false}  readOnly /></td>
                                <td>
                                    <button title="View" className="text-2xl m-1 !rounded-[5px] btn btn-error btn-outline" onClick={ ()=>{removeUser(index)}}
                                    >
                                        <AiFillDelete/>
                                    </button>
                                    {/*<EditThumbnail />*/}
                                    <label htmlFor="editModal" title="View" className="text-2xl m-1 !rounded-[5px] btn-success btn btn-outline " onClick={ ()=>{handleEdit(index)}}
                                    >
                                        <AiFillEdit/>
                                    </label>
                                </td>
                            </tr>)
                        })
                    }
                    </tbody>
                </table>
            </div>


            <EditThumbnail show={true} dataRow={modalData}/>
            <AddThumbnail/>

        </>
    );
};
export default ThumbnailComponent;

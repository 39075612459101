import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";


const SignOutComponent=(props)=>{

    const navigate=useNavigate();

    const handleSignOut=()=>{
        localStorage.clear();
        navigate("/login")
        props.auth(false)
    }

    return(
        <>
            <button className="hover:bg-neutral hover:text-white" onClick={handleSignOut} >Logout</button>
        </>
    )
}
export default SignOutComponent;

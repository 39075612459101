import {
  FcSettings,
  FcUpLeft,
  FcRight,
  FcLeft,
  FcAlarmClock,
} from "react-icons/fc";

import { FcIdea, FcNoIdea, FcRefresh } from "react-icons/fc";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { FaExpandAlt, FaCompressAlt } from "react-icons/fa";
import { BsTranslate } from "react-icons/bs";
import { GiJumpAcross } from "react-icons/gi";
import { GrYoutube } from "react-icons/gr";
import Swal from "sweetalert2";
import { actionStyle } from "../Styles/style_variables";
import { initMathJax } from "../JsFunctions/initMathJax";
import { useNavigate } from "react-router-dom";
import { goToNextQuestion } from "../JsFunctions/nextQuestion";
import { GrGallery } from "react-icons/gr";
import GalleryModal from "./GalleryModal";
import { useState } from "react";
import axios from '../../../../api/axios';


export function PptBottomActionBar(props) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/dashboard");
    return;
  };
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token=localStorage.getItem('token');

  const loadImageData = async () => {
    if (images.length > 0) return
    const response = await fetch(`https://teachbackendapi.utkarshpublications.com/myThumbnails?token=${token}`,
    );
    const resData = await response.json();
    setImages(resData["data"]);
    setIsLoading(false)

  }

  return (
    <div className="actions_bar">
      <FcLeft
        style={actionStyle}
        onClick={() => {
          initMathJax();
          let nextQuestionNo = props.question_no - 1;
          if (nextQuestionNo == -1) {
            Swal.fire({
              position: "top-end",
              icon: "warning",
              title: "This is first question",
              showConfirmButton: false,
              timer: 2000,
            });
            return;
          }
          props.setQuestionNo(nextQuestionNo);
          props.setQuestionXposition(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .question_x_position
          );
          props.setQuestionYposition(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .question_y_position
          );
          props.setQuestionHeight(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .question_height
          );
          props.setQuestionWidth(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .question_width
          );
          props.setOptionXposition(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .option_x_position
          );
          props.setOptionYposition(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .option_y_position
          );
          props.setOptionHeight(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .option_height
          );
          props.setOptionWidth(
            JSON.parse(props.questions[nextQuestionNo].ppt_position_config)
              .option_width
          );
          props.setQuestionFontSize(
            JSON.parse(
              props.questions[nextQuestionNo].ppt_position_config
            ).question_font_size.replace("px", "")
          );
          props.setOptionFontSize(
            JSON.parse(
              props.questions[nextQuestionNo].ppt_position_config
            ).option_font_size.replace("px", "")
          );
        }}
      />
      <FcRight
        style={actionStyle}
        onClick={() => {
          initMathJax();
          goToNextQuestion(
            props.question_no,
            props.questions,
            props.updatePositionConfig,
            props.questionHeight,
            props.questionWidth,
            props.questionXposition,
            props.questionYposition,
            props.optionHeight,
            props.optionWidth,
            props.optionXposition,
            props.optionYposition,
            props.optionCurrentFontSize,
            props.setQuestionNo,
            props.setQuestionXposition,
            props.setQuestionYposition,
            props.setQuestionHeight,
            props.setQuestionWidth,
            props.setOptionXposition,
            props.setOptionYposition,
            props.setOptionHeight,
            props.setOptionWidth,
            props.setQuestionFontSize,
            props.setOptionFontSize
          );
        }}
      />
        <label htmlFor="settingModal">
            <FcSettings
                style={actionStyle}
            />
        </label>
        <GalleryModal isLoading={isLoading} images={images} />
        <label htmlFor="galleryModal" onClick={()=>loadImageData()} >
          <GrGallery style={actionStyle} />
        </label>


      <FaPlusCircle
        onClick={() => {
          let questionFont, optionFont;
          if (typeof props.questionFontSize === "string")
            questionFont = props.questionFontSize.replace("px", "");
          else questionFont = props.questionFontSize;

          if (typeof props.optionFontSize === "string")
            optionFont = props.optionFontSize.replace("px", "");
          else optionFont = props.optionFontSize;

          props.setQuestionFontSize(+questionFont + 2);
          props.setOptionFontSize(+optionFont + 2);
        }}
        style={actionStyle}
      />
      <FaMinusCircle
        onClick={() => {
          let questionFont, optionFont;
          if (typeof props.questionFontSize === "string")
            questionFont = props.questionFontSize.replace("px", "");
          else questionFont = props.questionFontSize;

          if (typeof props.optionFontSize === "string")
            optionFont = props.optionFontSize.replace("px", "");
          else optionFont = props.optionFontSize;
          props.setQuestionFontSize(+questionFont - 2);
          props.setOptionFontSize(+optionFont - 2);
        }}
        style={actionStyle}
      />
      <FaExpandAlt
        onClick={() => {
          var images = document.querySelectorAll("img");
          for (var i = 0; i < images.length; i++) {
            var image = images[i];
            image.style.width = image.width * 1.1 + "px";
            image.style.height = image.height * 1.1 + "px";
          }
        }}
        style={actionStyle}
      />
      <FaCompressAlt
        onClick={() => {
          var images = document.querySelectorAll("img");
          for (var i = 0; i < images.length; i++) {
            var image = images[i];
            image.style.width = image.width * 0.9 + "px";
            image.style.height = image.height * 0.9 + "px";
          }
        }}
        style={actionStyle}
      />
        <label htmlFor="questionJumpModal"><GiJumpAcross style={actionStyle} /></label>
      <FcAlarmClock
        style={actionStyle}
        onClick={() => {
          props.setShowSelectTimeBox(!props.showSelectTimeBox);
        }}
      />

      <BsTranslate
        onClick={() => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          var temp = !props.bilingualContent;
          props.setBilingualContent(temp);
          if (temp) {
            Toast.fire({
              icon: "success",
              title: "Viewing in billingual mode",
            });
          } else {
            Toast.fire({
              icon: "success",
              title: "Viewing in single lang mode",
            });
          }
        }}
        style={actionStyle}
      />

      {props.showPreviousOf ? (
        <FcIdea
          onClick={() => {
            props.setShowPreviousOf(!props.showPreviousOf);
          }}
          style={actionStyle}
        />
      ) : (
        <FcNoIdea
          onClick={() => {
            props.setShowPreviousOf(!props.showPreviousOf);
          }}
          style={actionStyle}
        />
      )}
      <FcRefresh
        onClick={(e) => {
          props.pptDataLoad(true, e);
        }}
        style={actionStyle}
      />
      <GrYoutube
        onClick={() => {
          props.setShowAutoPlaySecondSuggestion(
            !props.showAutoPlaySecondSuggestion
          );
        }}
        style={actionStyle}
      />
      <FcUpLeft onClick={goBack} style={actionStyle} />
    </div>
  );
}

export function correctOptionColorChange(correctOption) {

    switch (correctOption) {
        case "A":
            document.getElementById("option_a").style.backgroundColor = "#4bb543"
            document.getElementById('option_a').style.animation = 'fading 2s infinite'
            break;
        case "B":
            document.getElementById("option_b").style.backgroundColor = "#4bb543"
            document.getElementById('option_b').style.animation = 'fading 2s infinite'
            break;
        case "C":
            document.getElementById("option_c").style.backgroundColor = "#4bb543"
            document.getElementById('option_c').style.animation = 'fading 2s infinite'
            break;
        case "D":
            document.getElementById("option_d").style.backgroundColor = "#4bb543"
            document.getElementById('option_d').style.animation = 'fading 2s infinite'
            break;
        case "E":
            document.getElementById("option_e").style.backgroundColor = "#4bb543"
            document.getElementById('option_e').style.animation = 'fading 2s infinite'
            break;

        default:
            break;
    }
}


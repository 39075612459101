import React, { useState } from 'react';
import Header from "../PDF/Header";
import PDFBody from "../PDF/Index";
import Footer from "../PDF/Footer";
import ConfigComponent from "../../config.component";
import { saveAs } from 'file-saver';
import ReactDOMServer from 'react-dom/server';

function PDF() {
  const [contentFontSize, setContentFontSize] = useState([14]);
  const [showCorrectOption, setShowCorrectOption] = useState(false);
  const [discussionSpacing, setDiscussionSpacing] = useState([12]);
  const [showShortAnswer, setShowShortAnswer] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [showQuestions, setShowQuestions] = useState(true);
  const [showOptions, setShowOptions] = useState(true);
  const [showBillingual, setShowBillingual] = useState(['Hindi']);
  const [showPreviousYearTag, setShowPreviousYearTag] = useState(false);
  const [testDetail, settestDetail] = useState({});

  const convertHtmlToWord = (elementId) => {
    const htmlElement = document.getElementById(elementId);
    if (!htmlElement) {
      console.error(`Element with ID '${elementId}' not found.`);
      return;
    }

    // Remove the specific HTML code from the HTML content
    let htmlContent = htmlElement.innerHTML;
    const header = ReactDOMServer.renderToStaticMarkup(<Header testDetail={testDetail}/>);
    const footer = ReactDOMServer.renderToStaticMarkup(<Footer />);

    const codeToRemove = '<div class="watermark"><img id="company-logo"';
    const startIndex = htmlContent.indexOf(codeToRemove);
    if (startIndex !== -1) {
      const endIndex = htmlContent.indexOf('</div>', startIndex) + 6;
      htmlContent = htmlContent.substring(0, startIndex) + htmlContent.substring(endIndex);
    }

    const wordContent = `<!DOCTYPE html>
    <html xmlns:w="urn:schemas-microsoft-com:office:word">
    <head>
      <meta charset="utf-8">
      <title>Downloaded from Teach | Bigbooster</title>
    </head>
    <body>  
    <!-- ${header}-->
    ${htmlContent}
    ${footer}
    </body>
    </html>`;

    const wordBlob = new Blob([wordContent], {
      type: 'application/msword',
    });

    saveAs(wordBlob, 'document.doc');
  };


  return (
      <div className="bg-base-100" >
        <ConfigComponent
            setContentFontSize={setContentFontSize}
            setShowCorrectOption={setShowCorrectOption}
            setDiscussionSpacing={setDiscussionSpacing}
            setShowShortAnswer={setShowShortAnswer}
            setShowSolution={setShowSolution}
            setShowQuestions={setShowQuestions}
            setShowOptions={setShowOptions}
            setShowBillingual={setShowBillingual}
            setShowPreviousYearTag={setShowPreviousYearTag}
            contentFontSize={contentFontSize}
            showCorrectOption={showCorrectOption}
            discussionSpacing={discussionSpacing}
            showShortAnswer={showShortAnswer}
            showSolution={showSolution}
            showQuestions={showQuestions}
            showOptions={showOptions}
            showBillingual={showBillingual}
            showPreviousYearTag={showPreviousYearTag}
        />

        {/*<button className="btn m-1 btn-sm rounded-[2px]" onClick={() => convertHtmlToWord('exportContent')}>Export as .doc</button>*/}
        <button className="btn m-1 btn-sm hidden-print rounded-[2px]" onClick={() => convertHtmlToWord('exportContent')}>Export as .doc</button>

        <div >
          <Header testDetail={testDetail}/>
          <table
              style={{
                border: "5px solid #300454",
                width: "100vw",
                minHeight: "92vh",
              }}
          >
            <thead>
            <tr>
              <td>
                <hr style={{ backgroundColor: "transparent", margin: "2px" }} />
              </td>
            </tr>
            </thead>
            <tbody>
            <td id="exportContent">
              <PDFBody
                  contentFontSize={contentFontSize}
                  showCorrectOption={showCorrectOption}
                  discussionSpacing={discussionSpacing}
                  showShortAnswer={showShortAnswer}
                  showSolution={showSolution}
                  showQuestions={showQuestions}
                  showOptions={showOptions}
                  showBillingual={showBillingual}
                  showPreviousYearTag={showPreviousYearTag}
                  settestDetail={settestDetail}
              />
            </td>
            </tbody>
            <tfoot>
            <td>
              <tr>
                <Footer />
              </tr>
            </td>
            </tfoot>
          </table>
        </div>
      </div>
  );
}

export default PDF;

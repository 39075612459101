import { SketchPicker } from 'react-color'
import { FcSupport, FcIdea, FcNoIdea } from "react-icons/fc";
import { useState, useEffect } from 'react';

export function ModalInnerElement(props) {
    const [targetElementIndex, SetTargetElementIndex] = useState(0);
    var targetElement = (targetElementIndex) => {
        SetTargetElementIndex(targetElementIndex)
        document.getElementById(targetElementIndex).style.backgroundColor = "#29a69a"
        removeBackgroundColor();
    };
    var removeBackgroundColor = () => {
        document.getElementById(targetElementIndex).style.backgroundColor = "white"
    };
    const [color, setColor] = useState("black");
    var handleChangeComplete = (color) => {
        if (targetElementIndex == 0) {
            props.setQuestionTextColor(color.hex)
        } else if (targetElementIndex == 1) {
            props.setQuestionBackgroundColor(color.hex)
        } else if (targetElementIndex == 2) {
            props.setOptionTextColor(color.hex)
        } else if (targetElementIndex == 3) {
            props.setOptionBackgroundColor(color.hex)
        } else if (targetElementIndex == 4) {
            props.setScreenBackgroundColor(color.hex)
        }
    };
    return (
        <>

            <div className="allElement gap-4 cfont1">

                <button id="0" className="btn cfont1 btn-accent gap-2 btn-sm rounded-[2px]"  style={{ backgroundColor: "#29a69a" }} onClick={() => { targetElement(0) }}><FcSupport className="text-xl" />  Question Color</button>
                <button id="1" className="btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]" onClick={() => { targetElement(1) }}><FcSupport className="text-xl" />  Question Background Color</button>
                <button id="2" className="btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]" onClick={() => { targetElement(2) }}><FcSupport className="text-xl" />  Option Color</button>
                <button id="3" className="btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]" onClick={() => { targetElement(3) }}><FcSupport className="text-xl" />  Option Background Color</button>
                <button id="4" className="btn cfont1 btn-outline gap-2 btn-sm rounded-[2px]" onClick={() => { targetElement(4) }}><FcSupport className="text-xl" />  Screen Background Color</button>


                {/*<p id="0" style={{ backgroundColor: "lightgreen" }} onClick={() => { targetElement(0) }}>*/}
                {/*    <FcSupport /> Question Color</p>*/}
                {/*<p   className='c-font1' id="1" onClick={() => { targetElement(1) }}> <FcSupport /> Question Background Color</p>*/}
                {/*<p id="2" onClick={() => { targetElement(2) }}> <FcSupport /> Option Color</p>*/}
                {/*<p id="3" onClick={() => { targetElement(3) }}> <FcSupport /> Option Background Color</p>*/}
                {/*<p id="4" onClick={() => { targetElement(4) }}> <FcSupport /> Screen Background Color</p>*/}

            </div>
            <div className="divider"></div>
            <div className="sketchPickerAndOtherElement" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <SketchPicker
                    color={color} onChange={({ hex }) => setColor(hex)}
                    onChangeComplete={handleChangeComplete}
                />
                <div>
                    {/* <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onClick={() => {
                            if (props.updatePositionConfig == true) {
                                props.setUpdatePositionConfig(false)
                            } else {
                                props.setUpdatePositionConfig(true)
                            }
                        }} />
                        <label className="form-check-label" htmlFor="positionUpdate">Update Position</label>
                    </div> */}
                    <div className="form-control flex">
                        <label className="label cursor-pointer">
                            <input type="checkbox" id="flexSwitchCheckChecked" onClick={() => {
                            if (props.updatePositionConfig == true) {
                                props.setUpdatePositionConfig(false)
                            } else {
                                props.setUpdatePositionConfig(true)
                            }
                        }}className="toggle toggle-warning" />
                            <label className="ml-1 flex-1 cfont1" htmlFor="positionUpdate">Update Position</label>
                        </label>
                    </div>
                </div>

            </div>

        </>
    )
}

import { useState, useRef, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Lightbox } from "react-modal-image";
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill} from "react-icons/bs";
import LoadingComponent from "../../../loading.component";


const GalleryModal=({images, isLoading})=>{

    const closeBtnRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [imgUrl, setImgUrl] = useState("");
    // const [images, setImages] = useState([
    // ]);
    const [imgIndex, setImageIndex] = useState(0);
    // const [isLoading, setIsLoading] = useState(true);

    const token=localStorage.getItem('token');

    const onNext = () =>{
        let index = imgIndex + 1
        if(index < images.length ){
            setImgUrl(images[index].thumbnail_url);
            setImageIndex(index)
        }
    }
    
    const onPrev = () =>{
        let index = imgIndex - 1
        if(index >= 0){
            setImgUrl(images[index].thumbnail_url);
            setImageIndex(index)

        }
    }
    const onImgClick = (src, index) => {
        setImageIndex(index)
        setIsOpen(true);
        setImgUrl(src);
        
    }


        // useEffect(()=>{
        //     loadImageData();
        // },[])

    // if(isLoading) return <LoadingComponent />
    return(
        <>
           <div>
            {isOpen && <>

                <div className="w-full">
                    <div className="flex justify-start items-center h-screen" style={{zIndex: "9999"}}>
                        <button  className="m-4 fixed left-0 top-1/2 transform -translate-y-1/2" style={{zIndex: "9999"}} onClick={()=>{onPrev()}}><BsFillArrowLeftCircleFill className="text-4xl"/></button>
                    </div>
                    <div className="flex justify-end items-center h-screen" style={{zIndex: "9999"}}>
                        <button  className=" m-4 fixed right-0 top-1/2 transform -translate-y-1/2" style={{zIndex: "9999"}} onClick={()=>{onNext()}}><BsFillArrowRightCircleFill className="text-4xl"/></button>
                    </div>
                </div>
            
                <Lightbox
                large={imgUrl}
                hideDownload={true}
                // hideZoom={true}
                onClose={()=> setIsOpen(false)}
            />
            </> 
        }
           </div>


           {/* The button to open modal */}
        {/* <label htmlFor="my-modal-4" className="btn">open modal</label> */}

        {/* Put this part before </body> tag */}
        <input type="checkbox" id="galleryModal" className="modal-toggle" />
        <label htmlFor="galleryModal" className="modal cursor-pointer">
        <label className="modal-box w-full h-full max-w-full max-h-full relative !rounded-sm" htmlFor="">
            {/* start */}
        <div>
        <p className="text-2xl c-font1 font-bold c-font1">Thumbnails</p>
    <div className="card-actions justify-end">
    <label
        ref={closeBtnRef}
        htmlFor="galleryModal"
        className="btn btn-ghost btn-sm mt-[-30px]"
    >
        <RxCross2 />
    </label>
    </div>

        <h2 className="sr-only c-font1">Thumbnails</h2>
    {(isLoading)&& <div><LoadingComponent /></div>}
    {(!isLoading )&& (
    <div>

        <div className="grid grid-cols-1 gap-y-7 mt-2 gap-x-6 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 xl:gap-x-7 ">
            {images.map((img, index) => <div key={img.id} className="card w-50 bg-base-100 shadow-xl" onClick={()=>onImgClick(img.thumbnail_url, index)}>
                <figure className="px-5 pt-5">
                    <img src={img.thumbnail_url} alt={img.thumbnail_title} className="rounded-xl aspect-video" />
                </figure>
                <div className="card-body items-center text-center">
                    <h2 className="card-title c-font1">{img.thumbnail_title}</h2>
                </div>
                </div>)}
        </div>
    </div>
    )}
        </div>
            
            {/* end */}
            
        </label>
        </label>
           
        </>
    )
}
export default GalleryModal;
import NavbarComponent from "./components/navbar.component";
import FooterComponent from "./components/footer.component";
import {lazy, Suspense, useEffect, useState} from "react";
import {BrowserRouter as Router, Routes,Route} from "react-router-dom";
import DashboardPage from "./pages/dashboard.page";
import NoAuth from "./noauth";
import Auth from "./auth";
import SettingPage from "./pages/setting.page";
import ProfilePage from "./pages/profile.page";
import MainHome from "./components/home/main.home";
import LoadingComponent from "./components/loading.component";
import InternalDashboardPage from "./pages/internalDashboard.page";
import ConfigComponent from "./components/config.component";
import PDFBody from "./components/home/PDF/Index";
import PDF from "./components/home/PDF/pdf";
import Getmaterial from "./components/home/OnBoard/Index";
import Editor from "./components/editor.component";
import ThumbnailComponent from "./components/thumbnail.component";
import EditQuestion from "./pages/Questions/edit.question";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line no-undef
const SignInPage = lazy(() => import('./pages/signIn.page')); // Lazy-loaded


function App() {

    const [auth,setAuth]=useState();
    const [authStatus,setAuthStatus]=useState()

    const authS=(authStatus)=>{
        setAuthStatus(authStatus)
    } 
    
    // for navbar states
    const getAuth=()=>{
        setAuth(localStorage.getItem('token'))
    }
    useEffect(()=>(
        getAuth()
    ))

  return (
    <div className="App">

        <Router>
            <Suspense fallback={<LoadingComponent/>}>
            <NavbarComponent />
            <Routes>
                {/* Universal routes*/}
                <Route exact path="/" element={<MainHome/>} />
                <Route path="pdf" element={<PDF/>}/>
                <Route path="ppt" element={<Getmaterial/>}/>

                {/* un-auth routes*/}
                <Route path="/" exact element={<NoAuth/>} >
                    <Route path="login" element={<SignInPage auth={authS} />} />
                </Route>

                {/* auth routes*/}
                <Route path="/" exact element={<Auth/>} >
                    <Route path="dashboard" element={<DashboardPage auth={authS} />} />
                    <Route path="setting" element={<SettingPage />} />
                    {/*<Route path="dashboard/internal" element={<InternalDashboardPage auth={authS}/>} />*/}
                    <Route path="profile" element={<ProfilePage  />} />
                    <Route path="editor" element={<Editor/>}/>
                    <Route path="thumbnails" element={<ThumbnailComponent/>}/>
                    <Route path="dashboard/edit" element={<EditQuestion/>} />
                </Route>

            </Routes>
                <ToastContainer/>
                <FooterComponent />
            </Suspense>
        </Router>

    </div>
  );
}

export default App;

import { timerSecondBox, timerBoxAllElementForAutoPlaySuggestion, timerBoxAllElement } from "../Styles/style_variables";
export function AutoPlaySecondBox(props) {
    return <div className="timeHelper" style={timerBoxAllElementForAutoPlaySuggestion}>
        <div style={{ display: "flex", }}>
            <span onClick={() => {
                startAutoPlaying(props, 10)
            }} style={timerSecondBox}>10 sec</span>
            <span onClick={() => { startAutoPlaying(props, 15) }} style={timerSecondBox}>15 sec</span>
            <span onClick={() => { startAutoPlaying(props, 20) }} style={timerSecondBox}>20 sec</span>
        </div>

        <div style={{ display: "flex", }}>

            <span onClick={() => { startAutoPlaying(props, 20) }} style={timerSecondBox}>25 sec</span>
            <span onClick={() => { startAutoPlaying(props, 30) }} style={timerSecondBox}>30 sec</span>
            <span onClick={() => { startAutoPlaying(props, 35) }} style={timerSecondBox}>35 sec</span>
        </div>
        <div style={{ display: "flex", }}>

            <span onClick={() => { startAutoPlaying(props, 40) }} style={timerSecondBox}>40 sec</span>
            <span onClick={() => { startAutoPlaying(props, 45) }} style={timerSecondBox}>45 sec</span>
            <span onClick={() => { startAutoPlaying(props, 60) }} style={timerSecondBox}>60 sec</span>
        </div>
    </div>
}


function startAutoPlaying(props, timeSelected) {
    props.setQuestionAutoPlayInterval(timeSelected * 1000)
    props.setTimerDuration(timeSelected); props.setShowTimer(true); props.setKey(prevKey => prevKey + 1)
    props.setShowAutoPlaySecondSuggestion(!props.showAutoPlaySecondSuggestion)
    var audio = new Audio(process.env.PUBLIC_URL + '/sound.mp3');
    audio.play()
    audio.addEventListener('ended', function () {
        audio.currentTime = 0;
        audio.play();
    });
    props.setAutoPlaying(!props.autoPlaying);
}
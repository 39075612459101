import parse from 'html-react-parser';

export function Options(props) {
    return (
        <div className="options w3-animate-right" style={{ backgroundColor: props.optionBackgroundColor, color: props.optionTextColor, fontSize: props.optionFontSize + 'px' }}>
            <div id="option_a" className="individual-options">
                (a) {(props.bilingualContent) ? (props.option1_hin == props.option1_eng || props.option1_eng == undefined) ? parse(props.option1_hin) : parse(props.option1_hin + " • " + props.option1_eng) : parse(props.option1_hin)}
            </div>
            <div id="option_b" className="individual-options">
                (b) {(props.bilingualContent) ? (props.option2_hin == props.option2_eng || props.option2_eng == undefined) ? parse(props.option2_hin) : parse(props.option2_hin + " • " + props.option2_eng) : parse(props.option2_hin)}
            </div>
            <div id="option_c" className="individual-options">
                (c) {(props.bilingualContent) ? (props.option3_hin == props.option3_eng || props.option3_eng == undefined) ? parse(props.option3_hin) : parse(props.option3_hin + " • " + props.option3_eng) : parse(props.option3_hin)}
            </div>
            <div id="option_d" className="individual-options">
                (d) {(props.bilingualContent) ? (props.option4_hin == props.option4_eng || props.option4_eng == undefined) ? parse(props.option4_hin) : parse(props.option4_hin + " • " + props.option4_eng) : parse(props.option4_hin)}
            </div>

            {(props.option5_hin == "" || props.option5_hin == undefined) ? false :
                <div id="option_e" className="individual-options">
                    (e) {(props.bilingualContent) ? (props.option5_hin == props.option5_eng || props.option5_eng == undefined) ? parse(props.option5_hin) : parse(props.option5_hin + " • " + props.option5_eng) : parse(props.option5_hin)}
                </div>}
        </div>

    );
}


export function optionCurrentFontSize() {
    var fontSize = window.getComputedStyle(document.getElementById('option_a')).fontSize
    return fontSize;
}

import "../../css/ppt.css";
import { Questions, questionCurrentFontSize } from "./Components/Questions";
import { Options, optionCurrentFontSize } from "./Components/Options";
import { Rnd } from "react-rnd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import axios from "../../Api/axios";
import { getDataInLoop } from "./JsFunctions/getDataInLoop";
import { initMathJax } from "./JsFunctions/initMathJax";
import { goToNextQuestion } from "./JsFunctions/nextQuestion";
import { Timer } from "./Components/Timer";
import { ModalInnerElement } from "./Components/SettingModal";
import { PptBottomActionBar } from "./Components/BottomActionBar";
import { TimerSecondBoxSuggestion } from "./Components/TimerSecondBoxSuggestion";
import { JumpQuestionModal } from "./Components/JumpQuestionModal";
import { AutoPlaySecondBox } from "./Components/AutoPlaySecondBox";
import { RxCross2 } from "react-icons/rx";
import { useRef } from "react";
import Swal from "sweetalert2";
import LoadingComponent from "../../loading.component";

function Getmaterial() {
  const location = useLocation();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [autoPlaying, setAutoPlaying] = useState(false);
  let configData = location.state?.data;

  const closeBtnRef = useRef(null);


  const fetchDataUtakrsh = async () => {
    try {
      const response = await fetch(`https://teachbackendapi.utkarshpublications.com/utk/getSetQuestion/${configData}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if(data.Status){
        const dataRes=transformData(data.data);
        setQuestions(dataRes)
        initMathJax()
        assignDataToVariables(dataRes);
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "No data found",
        });
        return navigate(location.state?.path || "/")//prev
      }

    } catch (error) {
      console.error('Error:', error.message);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something went Wrong",
      });
      return navigate(location.state?.path || "/")//prev
    }
  };

  // console.log(configData)
  const transformData = (questions) => {
    return questions.map((question1, index) => {
      question1.question_no=index+1; 
      if(question1.answer==1){
        question1.answer="A";
      }else if(question1.answer==2){
        question1.answer="B";
      }else if(question1.answer==3){
        question1.answer="C";
      }else if(question1.answer==4){
        question1.answer="D";
      }else if(question1.answer==5){
        question1.answer="E";
      }

      /*question1.ppt_position_config=JSON.stringify({
          question_height: "846px",
          question_width: "743px",
          question_x_position: 0,
          question_y_position: 0,
          question_font_size: "40px",
          option_height: "193px",
          option_width: "417px",
          option_x_position: 920.666748046875,
          option_y_position: 0,
          option_font_size: "20px",
      });*/
      return question1;
    });
  }; // data format

  // console.log(transformedData)

  const getDataInLoop = async (url, offset) => {
    let options = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer keyrPNFKcIz6BrXFR`,
      },
    };
    const response = await fetch(url + "&offset=" + offset, options);
    const resData = await response.json();
    for (let index = 0; index < resData.records.length; index++) {
      let element = resData.records[index];
      resData.records[index] = { ...element, ...element.fields };
    }
    setQuestions((prev) => {
      return [...prev, ...resData.records];
    });

    if ((resData?.offset || "") != "") {
      await getDataInLoop(url, resData?.offset);
    }
  };

  const pptDataLoad = async (isRefreshed = false, e = false) => {
    if (location.state.data.endpoint) {
      let options = {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer keyrPNFKcIz6BrXFR`,
        },
      };
      let resData = [];
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      try {
        const response = await fetch(location.state.data.endpoint, options);
        resData = await response.json();
      } catch (e) {
        Toast.fire({
          icon: "error",
          title: "Something went Wrong",
        });
        return navigate(location.state?.path || "/"); //prev
      }
      if (isRefreshed) {
        debugger;
        // e.target.style.cursor = "not-allowed";
        Toast.fire({
          icon: "info",
          title: "data refreshing...",
        });
      }
      if (resData["status"] === 404) {
        Toast.fire({
          icon: "error",
          title: "No data found",
        });
        return navigate(-1); //prev
      }
      let offset = resData?.offset || "";
      for (let index = 0; index < resData.records.length; index++) {
        let element = resData.records[index];
        resData.records[index] = { ...element, ...element.fields };
      }
      setQuestions(resData["records"]);
      if (offset != "") {
        await getDataInLoop(location.state.data.endpoint, offset);
      }
      // assignDataToVariables(resData["records"]);
      initMathJax();
      if (isRefreshed) {
        // e.target.style.cursor = "pointer"
        Toast.fire({
          icon: "success",
          title: "data refreshed",
        });
      }
    } else {
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          set_id: configData.set_id,
          set_password: configData.set_password,
          endpoint: "",
        }),
      };
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      if (isRefreshed) {
        // e.target.style.cursor = "not-allowed";
        Toast.fire({
          icon: "info",
          title: "data refreshing...",
        });
      }
      const response = await fetch(
        `https://teachbackendapi.utkarshpublications.com/app-to-web`,
        options
      );
      const resData = await response.json();
      if (resData["status"] === 404) {
        Toast.fire({
          icon: "error",
          title: "No data found",
        });
        return navigate(-1); //prev
      }

      if (resData['data']) {
        const excludesString = ["testbook.com", "googleapi.com"];
        for (const obj of resData['data']) {
          const propertiesToCheck = [
            'question_hin',
            'question_eng',
            'option1_eng',
            'option2_eng',
            'option3_eng',
            'option4_eng',
            'option5_eng',
            'option1_hin',
            'option2_hin',
            'option3_hin',
            'option4_hin',
            'option5_hin',
          ];
          const isExcluded = propertiesToCheck.some((property) => {
            const value = obj[property];
            if (Array.isArray(value)) {
              return value.some((item) =>
                  excludesString.includes(String(item))
              );
            }
            return excludesString.includes(String(value));
          });

          if (isExcluded) {
            propertiesToCheck.forEach((property) => {
              obj[property] = "This question is preparing";
            });
          }
        }
      }

      setQuestions(resData["data"]);
      initMathJax();
      assignDataToVariables(resData["data"]);
      if (isRefreshed) {
        // e.target.style.cursor = "pointer"
        Toast.fire({
          icon: "success",
          title: "data refreshed",
        });
      }
    }
  };

  let initialized = false;

  useEffect(() => {
    if (configData && !Array.isArray(configData) && typeof configData !== 'object') {
      fetchDataUtakrsh();
    }else{
      if (location.state == undefined) {
        goBack();
      }
      // var url = location.state.url;
      var data = location.state.data;
      // var apiParams = {}
      // var temp = [];
      var temp = data;
      if (!initialized) {
        initialized = true;
        pptDataLoad();
      }
    }
    // debugger
  }, []);


  const assignDataToVariables = (temp) => {
    setQuestionXposition(
      JSON.parse(temp[question_no].ppt_position_config).question_x_position,
    );
    setQuestionYposition(
      JSON.parse(temp[question_no].ppt_position_config).question_y_position
    );
    setOptionXposition(
      JSON.parse(temp[question_no].ppt_position_config).option_x_position
    );
    setOptionYposition(
      JSON.parse(temp[question_no].ppt_position_config).option_y_position
    );
    setQuestionHeight(
      JSON.parse(temp[question_no].ppt_position_config).question_width
    );
    setQuestionWidth(
      JSON.parse(temp[question_no].ppt_position_config).question_width
    );
    setOptionHeight(
      JSON.parse(temp[question_no].ppt_position_config).option_height
    );
    setOptionWidth(
      JSON.parse(temp[question_no].ppt_position_config).option_width
    );
    setQuestionFontSize(
      JSON.parse(
        temp[question_no].ppt_position_config
      ).question_font_size.replace("px", "")
    );
    setOptionFontSize(
      JSON.parse(
        temp[question_no].ppt_position_config
      ).option_font_size.replace("px", "")
    );
  };
  const goBack = () => {
    navigate("/dashboard");
    return;
  };

  const [questionTextColor, setQuestionTextColor] = useState("red");
  const [questionBackgroundColor, setQuestionBackgroundColor] =
    useState("white");
  const [optionTextColor, setOptionTextColor] = useState("black");
  const [optionBackgroundColor, setOptionBackgroundColor] = useState("white");
  const [screenBackgroundColor, setScreenBackgroundColor] = useState("white");
  const [questionFontSize, setQuestionFontSize] = useState(40);
  const [optionFontSize, setOptionFontSize] = useState(40);
  const [question_no, setQuestionNo] = useState(0);
  const [questionXposition, setQuestionXposition] = useState(0);
  const [questionYposition, setQuestionYposition] = useState(0);
  const [questionHeight, setQuestionHeight] = useState(100);
  const [questionWidth, setQuestionWidth] = useState(100);
  const [optionXposition, setOptionXposition] = useState(0);
  const [optionYposition, setOptionYposition] = useState(0);
  const [optionHeight, setOptionHeight] = useState(100);
  const [optionWidth, setOptionWidth] = useState(100);
  const [updatePositionConfig, setUpdatePositionConfig] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [showSelectTimeBox, setShowSelectTimeBox] = useState(false);
  const [bilingualContent, setBilingualContent] = useState(false);
  const [showPreviousOf, setShowPreviousOf] = useState(false);
  const [timerDuration, setTimerDuration] = useState(10);
  const [key, setKey] = useState(0); // especially used for restarting timer
  const [loop, setLoop] = useState(1);
  const [showAutoPlaySecondSuggestion, setShowAutoPlaySecondSuggestion] =
    useState(false);
  const [questionAutoPlayInterval, setQuestionAutoPlayInterval] = useState(0);
  const [clockRunning, setClockRunning] = useState(true);

  useEffect(() => {
    if (autoPlaying) {
      let thisInterval = setInterval(
        () => setLoop(Math.random()),
        questionAutoPlayInterval + 5000
      );
      return () => clearInterval(thisInterval);
    }
  }, [autoPlaying]);

  useEffect(() => {
    if (loop != 1) {
      let nextQuestionNo = goToNextQuestion(
        question_no,
        questions,
        updatePositionConfig,
        questionHeight,
        questionWidth,
        questionXposition,
        questionYposition,
        optionHeight,
        optionWidth,
        optionXposition,
        optionYposition,
        optionCurrentFontSize,
        setQuestionNo,
        setQuestionXposition,
        setQuestionYposition,
        setQuestionHeight,
        setQuestionWidth,
        setOptionXposition,
        setOptionYposition,
        setOptionHeight,
        setOptionWidth,
        setQuestionFontSize,
        setOptionFontSize,
        true
      );
      setQuestionNo(nextQuestionNo);
      initMathJax();
      setTimerDuration(questionAutoPlayInterval / 1000);
      setShowTimer(true);
      setKey((prevKey) => prevKey + 1);
    }
  }, [loop]);

  return (
    <>
      {/* <p style={{ backgroundColor: questionBackgroundColor, color: questionTextColor, fontFamily: "Roboto Slab", fontSize: "25px", fontWeight: "bold" }}>   <marquee>Attempt these questions test on Bigbooster App • Get online test for SSC | Railway | CTET | Defence Exams | State Exams</marquee ></p> */}
      {questions.length == 0 ? (
        <LoadingComponent />
      ) : (
         <div
          className="PPT_Body"
          style={{ minHeight: "200vh", backgroundColor: screenBackgroundColor,width: "100%",marginTop:"10px" }} >
         <Rnd
            key={questions[question_no].question_no + "_rnd"}
            position={{ x: questionXposition, y: questionYposition }}
            size={{ width: questionWidth, height: questionHeight }}
            onDragStop={(e, d) => {
              setQuestionXposition(d.x);
              setQuestionYposition(d.y);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setQuestionHeight(ref.style.height);
              setQuestionWidth(ref.style.width);
              setQuestionXposition(position.x);
              setQuestionYposition(position.y);
            }}
          >
          {/*<div className="react-draggable" style={{ width: "60%",float: "left"}}> */}
            <Questions
              bilingualContent={bilingualContent}
              previous_of={questions[question_no].previous_of}
              showPreviousOf={showPreviousOf}
              question_no={questions[question_no].question_no}
              question_hin={questions[question_no].question_hin}
              question_eng={questions[question_no].question_eng}
              questionBackgroundColor={questionBackgroundColor}
              questionTextColor={questionTextColor}
              questionFontSize={questionFontSize}
            />
           </Rnd> 
          {/*</div> */}
          
          <Rnd
            position={{ x: optionXposition, y: optionYposition }}
            size={{ width: optionWidth, height: optionHeight }}
            key={questions[question_no].question_no + "_rnd2"}
            onDragStop={(e, d) => {
              setOptionXposition(d.x);
              setOptionYposition(d.y);
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setOptionHeight(ref.style.height);
              setOptionWidth(ref.style.width);
              setOptionXposition(position.x);
              setOptionYposition(position.y);
            }}
          >
         {/*  <div className="react-draggable" style={{ width: "40%",float: "right",paddingLeft:"10px"}}> */ }
          
          {questions[question_no].option1_hin == "" &&
            questions[question_no].option1_hin == "" &&
            questions[question_no].option1_hin == "" &&
            questions[question_no].option1_hin == "" ? (
              false
            ) : (
              <Options
                bilingualContent={bilingualContent}
                option1_hin={(
                  questions[question_no].option1_hin || ""
                ).replaceAll(`\\′`, `'`)}
                option1_eng={(
                  questions[question_no].option1_eng || ""
                ).replaceAll(`\\′`, `'`)}
                option2_hin={(
                  questions[question_no].option2_hin || ""
                ).replaceAll(`\\′`, `'`)}
                option2_eng={(
                  questions[question_no].option2_eng || ""
                ).replaceAll(`\\′`, `'`)}
                option3_hin={(
                  questions[question_no].option3_hin || ""
                ).replaceAll(`\\′`, `'`)}
                option3_eng={(
                  questions[question_no].option3_eng || ""
                ).replaceAll(`\\′`, `'`)}
                option4_hin={(
                  questions[question_no].option4_hin || ""
                ).replaceAll(`\\′`, `'`)}
                option4_eng={(
                  questions[question_no].option4_eng || ""
                ).replaceAll(`\\′`, `'`)}
                option5_hin={(
                  questions[question_no].option5_hin || ""
                ).replaceAll(`\\′`, `'`)}
                option5_eng={(
                  questions[question_no].option5_eng || ""
                ).replaceAll(`\\′`, `'`)}
                optionBackgroundColor={optionBackgroundColor}
                optionTextColor={optionTextColor}
                optionFontSize={optionFontSize}
                setOptionFontSize={setOptionFontSize}
              />
            )}
          {/* </div> */ }
           <div style={{ clear:"both" }}></div>
          </Rnd>
          {showTimer ? (
            <Rnd
              default={{
                x: 10,
                y: 160,
                width: 320,
                height: 200,
              }}
            >
              <Timer
                setShowTimer={setShowTimer}
                timerDuration={timerDuration}
                uniqueKey={key}
                autoPlaying={autoPlaying}
                correctOption={questions[question_no]["answer"]}
                clockRunning={clockRunning}
                setClockRunning={setClockRunning}
              />
            </Rnd>
          ) : (
            false
          )}
        </div>
      )}
      <PptBottomActionBar
        optionYposition={optionYposition}
        optionXposition={optionXposition}
        optionCurrentFontSize={optionCurrentFontSize}
        autoPlaying={autoPlaying}
        setAutoPlaying={setAutoPlaying}
        showPreviousOf={showPreviousOf}
        setShowPreviousOf={setShowPreviousOf}
        bilingualContent={bilingualContent}
        setBilingualContent={setBilingualContent}
        questions={questions}
        question_no={question_no}
        setQuestionNo={setQuestionNo}
        optionFontSize={optionFontSize}
        setOptionFontSize={setOptionFontSize}
        questionFontSize={questionFontSize}
        setQuestionFontSize={setQuestionFontSize}
        showSelectTimeBox={showSelectTimeBox}
        setShowSelectTimeBox={setShowSelectTimeBox}
        questionXposition={questionXposition}
        setQuestionXposition={setQuestionXposition}
        questionYposition={questionYposition}
        setQuestionYposition={setQuestionYposition}
        questionHeight={questionHeight}
        setQuestionHeight={setQuestionHeight}
        setQuestionWidth={setQuestionWidth}
        setOptionXposition={setOptionXposition}
        setOptionYposition={setOptionYposition}
        setOptionHeight={setOptionHeight}
        setOptionWidth={setOptionWidth}
        updatePositionConfig={updatePositionConfig}
        questionWidth={questionWidth}
        questionCurrentFontSize={questionCurrentFontSize}
        optionHeight={optionHeight}
        optionWidth={optionWidth}
        setTimerDuration={setTimerDuration}
        setShowTimer={setShowTimer}
        setKey={setKey}
        showAutoPlaySecondSuggestion={showAutoPlaySecondSuggestion}
        setShowAutoPlaySecondSuggestion={setShowAutoPlaySecondSuggestion}
        pptDataLoad={pptDataLoad}
      />
      {showSelectTimeBox ? (
        <TimerSecondBoxSuggestion
          setTimerDuration={setTimerDuration}
          setShowTimer={setShowTimer}
          setShowSelectTimeBox={setShowSelectTimeBox}
          setKey={setKey}
        />
      ) : (
        false
      )}

      {showAutoPlaySecondSuggestion ? (
        <AutoPlaySecondBox
          autoPlaying={autoPlaying}
          setAutoPlaying={setAutoPlaying}
          setTimerDuration={setTimerDuration}
          setShowTimer={setShowTimer}
          setKey={setKey}
          showAutoPlaySecondSuggestion={showAutoPlaySecondSuggestion}
          setShowAutoPlaySecondSuggestion={setShowAutoPlaySecondSuggestion}
          setQuestionAutoPlayInterval={setQuestionAutoPlayInterval}
        />
      ) : (
        false
      )}

      <input type="checkbox" id="settingModal" className="modal-toggle" />

      <label htmlFor="settingModal" className="modal cursor-pointer">
        <label className="modal-box relative  !rounded-sm" htmlFor="">
          <div className="card-actions  mt-4 mb-2 justify-end">
            <label
              ref={closeBtnRef}
              htmlFor="settingModal"
              className="btn btn-ghost btn-sm mt-[-30px]"
            >
              <RxCross2 />
            </label>
          </div>
          <ModalInnerElement
            setUpdatePositionConfig={setUpdatePositionConfig}
            setQuestionBackgroundColor={setQuestionBackgroundColor}
            setQuestionTextColor={setQuestionTextColor}
            setOptionBackgroundColor={setOptionBackgroundColor}
            setOptionTextColor={setOptionTextColor}
            setScreenBackgroundColor={setScreenBackgroundColor}
            updatePositionConfig={updatePositionConfig}
            setQuestionXposition={setQuestionXposition}
          />
        </label>
      </label>

      <JumpQuestionModal
        questions={questions}
        question_no={question_no}
        setQuestionNo={setQuestionNo}
      />
    </>
  );
}
export default Getmaterial;

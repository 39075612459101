import { useState } from 'react';

function Question(props) {
    var record_id = props.record_id
    var question_no = props.question_no
    var previous_of = props.previous_of
    var showPreviousYearTag = props.showPreviousYearTag
    var question_no_availability = true;

    var question_hin = props.question_hin
    var question_eng = props.question_eng
    
    var paragraph_text = props.paragraph_text;

    // var question_hin = props.question_hin.includes("testbook.com") || props.question_hin.includes("testbook.com")? "This question is preparing" : props.question_hin;
    // var question_eng = props.question_eng.includes("testbook.com") || props.question_eng.includes("testbook.com")? "This question is preparing" : props.question_eng;


    if (question_no == "" || question_no == undefined || question_no == null) {
        question_no_availability = false
    }
    return (
        <div className="Questions bg-transparent	">
            <div className="single_question" onClick={() => { }} id={props.question_no} style={{ fontSize: props.fontSize + "px" }}>
            
                <div style={!question_no_availability ? { marginLeft: '20px' } : {}} className="customQuestionMathAJAX">
                    
                    {paragraph_text==""?"":<p className="hindi-question-paragraph">{paragraph_text}</p>}
                    
                    <p className="hindi-question-paragraph" id={record_id}>
                        <span className="indexPDF !text-black"> {(question_no_availability != true) ? false : (`${question_no}` + ".\xa0\xa0")} </span>
                        {question_hin}</p>
                    {question_eng == question_hin ? false : <p class="english-question-paragraph" >{question_eng != false ? question_eng : false}</p>}
                    {
                        showPreviousYearTag ? <span id="previous_year_tag" style={previousYearComponentStyle}>{(previous_of == 0) ? "Expected" : previous_of}</span> : false
                    }
                </div>
            </div>
        </div>
    );
}


const previousYearComponentStyle = {
    fontSize: "10px",
    float: "right",
    marginTop: "1px",
    border: "1px solid #300454",
    borderRadius: "2px",
    padding: "0px 10px",
    background: "#300454",
    color: "white",
}

export default Question;

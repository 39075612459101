import { useState, useEffect } from 'react';
import QRCode from "react-qr-code";
function Header({testDetail}) {
    const [headerImage, setHeaderImage] = useState(['logo.png']);
    const [organisationTitle, setOrganisationTitle] = useState(['Prashnkosh - Best App For Online Test']);
    const [instituted_by, setInstituted_by] = useState(['Teacher Name']);

    const teacher = JSON.parse(localStorage.getItem('teacher')) || {};

    //console.log("sdfsss"+testDetail.testDetail.test_name);
    console.log(testDetail);

    useEffect(() => {

        if (teacher.teacher_name) setInstituted_by(`By ${teacher.teacher_name}`);
        if (teacher.institute_name) setOrganisationTitle(teacher.institute_name);

    }, [])

    return (
    <div className="Header  bg-neutral" style={{ color: "white" }}>
        <div className="image-and-tagline ">
            <img src={teacher.logo_url || "images/logo.png"} alt="logo" width="150px" height="105px" className="bg-base-100 company-logo--- my-auto mx-1" />
        </div>
        <div className="details">
            <div id="institution_name">
                <span contentEditable="true">{organisationTitle}</span>
            </div>
            <div id="other_header_details">
                
                <span contentEditable="true" style={{ borderColor: "white" }} id="contact" >
                SERIES CODE : {testDetail.series_code}
                </span>
            
                <span contentEditable="true" style={{ borderColor: "white" }} id="contact" >
                SET CODE: {testDetail.set_code}
                </span>

                <span contentEditable="true" style={{ borderColor: "white" }} id="contact" >
                TEST ID :  {testDetail.test_id}
                </span>

                <span contentEditable="true" style={{ borderColor: "white",display:"none" }} id="instituted_by" >
                    {instituted_by}
                </span>
            </div>
            <div id="other_header_details">
                <span contentEditable="true" style={{ borderColor: "white" }} id="pdf_name">
                TEST NAME: {testDetail.test_name}
                </span>
            </div>
            <div style={{textAlign:'center'}}> इन प्रश्नों का Quiz के रूप में अभ्यास करने एवं व्याख्या सहित उत्तर प्राप्त करने के लिए QR Code स्कैन करें</div>
        </div>
        <div className="ppimage-and-tagline my-auto mr-4" style={{maxWidth:200}}>
            <div style={{ height: "auto", margin: "0 auto", maxWidth:150, width: "100%",padding:"4px" }} className=" bg-base-100">
                {testDetail.deeplink_practice && (<QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={testDetail.deeplink_practice}
                    viewBox={`0 0 256 256`}
                    />
                )} 
            </div>
        </div>
    </div>  
    );
}

export default Header;

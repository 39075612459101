import {useEffect, useState} from "react";
import anim from "../assets/90425-profile.json"
import Lottie from "lottie-react";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import axios from '../api/axios';


const ProfilePage=(props)=>{
    
    // console.log(props.teacher)
    const teacher = JSON.parse(localStorage.getItem('teacher')) || {};
    const navigate = useNavigate();



    const [instituteName,setInstituteName]=useState(teacher.institute_name);
    const [logoUrl,setLogoUrl]=useState(teacher.logo_url);
    const [phoneNo,setPhoneNo]=useState(teacher.phone_no);
    const [teacherName,setTeacherName]=useState(teacher.teacher_name);
    // const [userId,setUserId]=useState()
    const [whatsApp,setWhatsApp]=useState(teacher.whatsapp_no);
    const token=localStorage.getItem('token');

    const updateProfile = async (e) => {
        e.preventDefault();
        let options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            'token': token,
            'institute_name': instituteName,
            'teacher_name': teacherName,
            'phone_no': phoneNo,
            'whatsapp_no': whatsApp
          }),
        };
        const response = await fetch(`https://teachbackendapi.utkarshpublications.com/updateProfile`,
          options
        );
        try{
            const data = await response.json();
            localStorage.clear();
            Swal.fire(
                {title:'Profile updated, login again',
                    icon:'info',
                    confirmButtonColor: '#242B2E', allowOutsideClick: false,
                    allowEscapeKey: false,}
            ).then((result) => {
                if (result.isConfirmed) {
                    // window.location.reload(true) // tmp solution
                }
            });
            navigate("/login");
        }catch(e){
            navigate("/login");
        }

    }

    // set initial values
    // useEffect(()=>{
    //
    // },[props.teacher])

    return(
        <>
            <div className="justify-center flex min-h-screen bg-base-200">
                <div className=" flex-col lg:flex-row mt-10">
                    {/* <div className="text-center text-white lg:text-left">
                    <Lottie animationData={anim}  className="w-7/12" />

                        <h1 className="text-5xl font-bold">Teach for Teachers!</h1>
                        <p className="py-6">Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda excepturi exercitationem quasi. In deleniti eaque aut repudiandae et a id nisi.</p>
                    </div> */}
                    <div>
                        <form className="!c-font1" onSubmit={updateProfile}>
                            <div className="card flex-shrink-0 w-96 max-w-sm shadow-2xl bg-base-100 !rounded-lg">
                                <div className="card-body">
                                    {/* <div className="form-control">
                                        <label className="label">
                                            <span className="label-text !c-font1">User Id</span>
                                        </label>
                                        <input type="text" placeholder="User Id" className="!c-font1 input input-bordered"
                                               value={userId} onChange={(e)=> setUserId(e.target.value)}
                                        />
                                    </div> */}
                                    <div className="form-control flex-wrap items-center justify-center">
                                    <div class="w-10 rounded-full ring ring-[white] ring-offset-neutral ring-offset-2 mr-2">
                                        <img src={false || "https://image.bigbooster.in/e2e217b9-ddd7-4ef8-8ceb-f01125c3d482.png"} className="rounded-full bg-white"/>
                                    </div>
                                    </div>
                                    <div className="form-control">
                                        <label className="label ">
                                            <span className="label-text c-font1 font-bold">Teacher Name</span>
                                        </label>
                                        <input type="text" placeholder="Teacher Name" className="input input-bordered c-font1 text-sm !rounded-sm" value={teacherName}
                                               onChange={(e)=> setTeacherName(e.target.value)}/>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text c-font1 font-bold">Institute Name</span>
                                        </label>
                                        <input type="text" placeholder="email" className="input input-bordered c-font1 text-sm !rounded-sm"
                                               value={instituteName} onChange={(e)=> setInstituteName(e.target.value)}/>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text c-font1 font-bold">Phone No</span>
                                        </label>
                                        <input type="text" placeholder="Phone No" className="input input-bordered c-font1 text-sm !rounded-sm" value={phoneNo}
                                               onChange={(e)=> setPhoneNo(e.target.value)}/>
                                    </div>
                                    <div className="form-control">
                                        <label className="label">
                                            <span className="label-text c-font1 font-bold">WhatsApp No</span>
                                        </label>
                                        <input type="text" placeholder="WhatsApp No" className="input input-bordered c-font1 text-sm !rounded-sm"
                                               value={whatsApp} onChange={(e)=> setWhatsApp(e.target.value)}/>
                                    </div>
                                    <div className="form-control mt-6">
                                        <button className="btn c-font1 !rounded-sm normal-case" type="submit">Update</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProfilePage;

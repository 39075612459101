import {useEffect, useState} from "react";
import {AiFillDelete, AiFillEdit} from "react-icons/ai";
import Swal from "sweetalert2";
import axios from '../api/axios';

// import EditComponent from "../components/edit.component";

const SettingPage=()=>{

    const [users,setusers]=useState([]);

    const [user,setuser]=useState([]);

    const [query, setQuery] = useState("")

    const [date, setDate] = useState(new Date().toISOString().substr(0, 10));

    async function getUsers(date){
        const response= await fetch(`https://teachbackendapi.utkarshpublications.com/getMonitoringStatus?date=${date}`);
        const data= await response.json();
        setusers(data['data'])
        // console.log("tmp"+data['data'])
    }

    useEffect(() => {
        getUsers(date); // initial call

        const intervalId = setInterval(() => {
            getUsers(date);
            // console.log("5 sec")
        }, 5000);

        return () => clearInterval(intervalId);
    }, []);



    async function getUser(id){
        // const response= await fetch(`http://localhost:5003/api/getBook/${id}`);
        // const data = await response.json();
        // setuser(data);
    }

    async function removeUser(user_id){
        // console.log("hi")
        Swal.fire(
            {title:'Are you Sure to remove?',
                icon:'warning',
                confirmButtonColor: 'black', allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                allowEscapeKey: true,}
        ).then(async (result) => {
            if (result.isConfirmed) {

            }
        })

    }

    function handleEdit(id) {
        getUser(id)
        // setSEditBook(true)
    }

    let result = [];

    function mergeObjectsByProperty(arr, prop) {

        // Loop through the array of objects
        arr.forEach(obj => {
            // Get the value of the property for the current object
            const value = obj[prop];

            // If the value already exists in the result object,
            // merge the current object with the existing object
            if (result[value]) {
                Object.keys(obj).forEach(key => {
                    // Skip the property used for merging
                    if (key !== prop) {
                        // If the property is already an array, push the value
                        // Otherwise, create a new array with the current value
                        if (Array.isArray(result[value][key])) {
                            result[value][key].push(obj[key]);
                        } else {
                            result[value][key] = [result[value][key], obj[key]];
                        }
                    }
                });
            } else {
                // Otherwise, add the current object to the result object
                result[value] = obj;
            }
        });

        // Convert the result object back to an array of objects
        return Object.values(result);
        // return console.log(Object.values(result))
    }

    mergeObjectsByProperty(users, "user_id");

    return(
        <>

            {/*<EditComponent user={user} />*/}

            <div className="overflow-x-auto m-2">
                <div className="justify-self-end">
                    <div className="form-control mb-2">
                        <label className="input-group ">
                            <span>Date</span>
                            <input type="date" id="date" name="date" className="input input-bordered " value={date} onChange={(e)=>{
                                setDate(e.target.value)}} />
                        </label>
                    </div>
                </div>
                {/*<table className="table w-full">*/}
                {/*    <thead>*/}
                {/*    <tr>*/}
                {/*        <th></th>*/}
                {/*        <th>Name</th>*/}
                {/*        <th>Action Type</th>*/}
                {/*        <th className="text-right">*/}
                {/*            <form className="d-flex mb-2 " role="search">*/}
                {/*                <input placeholder="Search User" className="input input-bordered w-full max-w-xs" type="search" onChange={event => setQuery(event.target.value)}  aria-label="Search"/>*/}
                {/*            </form>*/}
                {/*        </th>*/}
                {/*    </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*    { users.filter(user => {*/}
                {/*        if (query === '') {*/}
                {/*        return user;*/}
                {/*    } else if (user.teacher_name.toLowerCase().includes(query.toLowerCase())) {*/}
                {/*        return user;*/}
                {/*    }*/}
                {/*    }).map((e, id) => (*/}
                {/*        <tr className="hover">*/}
                {/*            <th>{id+1}</th>*/}

                {/*            { typeof e.teacher_name === 'string'?  <td >{e.teacher_name}</td>*/}
                {/*                :*/}
                {/*                <td >{e.teacher_name[0]}</td>*/}
                {/*            }*/}
                {/*            { typeof e.action_type === 'string'?*/}
                {/*                <td >*/}
                {/*                    {e.action_type}*/}
                {/*                    <div className="badge badge-sm badge-success m-2">{e.total}</div>*/}
                {/*                </td>*/}
                {/*                :*/}
                {/*                e.action_type.map((e, k) => (*/}
                {/*                    <td key={k} >*/}
                {/*                        {e}*/}
                {/*                        <p className="badge badge-sm badge-success ">{e.total[k]}</p>*/}
                {/*                    </td>*/}
                {/*                ))*/}
                {/*            }*/}
                {/*            <td className="text-right">*/}
                {/*                <button title="View" className="text-2xl btn  btn-ghost" onClick={ ()=>{removeUser(e.user_id)}}*/}
                {/*                >*/}
                {/*                    <AiFillDelete />*/}
                {/*                </button>*/}

                {/*                <label htmlFor="editModal" title="View" className="text-2xl btn btn-ghost " onClick={ ()=>{handleEdit(e.user_id)}}*/}
                {/*                >*/}
                {/*                    <AiFillEdit/>*/}
                {/*                </label>*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*    ))*/}
                {/*    }*/}

                {/*    </tbody>*/}
                {/*</table>*/}
            </div>
        </>
    )
}
export default SettingPage;

import React, { useRef } from 'react';
import FileUploadComponent from './fileUpload.component';
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';

function Editor() {
    const editor = useRef(null);
    return (
        <>
            <div className="bg-base-100 pb-4">

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div>
                <JoditEditor
                    ref={editor}
                    value={"Welcome sir/mam"}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => console.log(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={newContent => {
                    }}
                />
                <FileUploadComponent />
            </div>
            </div>
        </>
    )
}

export default Editor

import {useEffect, useState} from "react";
import LoadingComponent from "../components/loading.component";
import {useNavigate, useLocation, Link} from "react-router-dom";
import Swal from "sweetalert2";
import {AiFillEdit} from "react-icons/ai";
import axios from '../api/axios';



const DashboardPage=(props)=>{

    const [mySets,setMySets]=useState([]);
    const [filteredSetsData, setFilteredSetsData]=useState(["loading"]);
    const [search,setSearch]=useState("");
    const badgeClasses = ["!bg-blue-50 !text-blue-800",
        "!bg-amber-50 !text-amber-800",
        "!bg-pink-50 !text-pink-800",
        "!bg-green-50 !text-green-800",
        "!bg-teal-50 !text-teal-800",
        "!bg-cyan-50 !text-cyan-800",
        "!bg-indigo-50 !text-indigo-800",
        "!bg-purple-50 !text-purple-800",
        "!bg-brown-50 !text-brown-800"
    ];
    let config = JSON.parse(localStorage.getItem('teacher')) || {};
    const navigate = useNavigate();
    const location = useLocation();

    const navigateToPage = async (e, toPage = "/", data) => {
        e.target.classList.add("loading");
        e.target.style.minWidth = "137px";

        navigate(toPage, { state: {data:data,  path:location.pathname}});
    };



    const handleSignOut=()=>{
        localStorage.clear();
        navigate("/login")
        Swal.fire(
            {title:'Please login to continue',
                icon:'info',
                confirmButtonColor: '#242B2E', allowOutsideClick: false,
                allowEscapeKey: false,}
        ).then((result) => {
            if (result.isConfirmed) {
                // window.location.reload(true) // tmp solution
            }
        })
    }
    const getMySets = async () => {
        let token = localStorage.getItem('token');
        if(!token) return;

        const response= await fetch(`https://teachbackendapi.utkarshpublications.com/mySets?token=${token}`);
        let data= await response.json();
        if(data["status"] === 404) return handleSignOut();
        setMySets(data['data']);

        setFilteredSetsData(data['data']);
        // console.log(data['data'])
    }

    const onSearchChange = (e) => {
        let searchStr = e.target.value;
        setSearch(searchStr);
        searchStr = (e.target.value || "").toLowerCase();
        if(searchStr == ""){
            setFilteredSetsData(mySets);
            return;

        }

        let filteredData = mySets.filter(set=>set.set_name.toLowerCase().includes(searchStr));
        setFilteredSetsData(filteredData);
    }

    const getRandomBadgeClass = () => badgeClasses[Math.floor(Math.random()*badgeClasses.length)];
    // console.log(data)

    useEffect(()=>{
        props.auth(false)
    },[])

    useEffect(()=>{
        getMySets();
    },[])


    return (
        <>

            {/* Sets Component */}

            <div className="p-4 bg-base-200 ">
                <div className="grid  grid-cols-3">
                    <div className="col-span-2">
                        <p className="text-xl mb-2 font-bold ">Available Sets</p>
                    </div>
                    <div className="justify-self-end">
                        <div className="form-control mb-2">
                            <div className="border rounded overflow-hidden flex">
                                <input
                                    type="text"
                                    className="px-4 py-2 c-font1"
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(e)=>onSearchChange(e)}
                                />
                                <button className="flex items-center justify-center px-4 border-l">
                                    <svg
                                        className="h-4 w-4 text-grey-dark"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid  grid-cols-3">
                    <div className="col-span-2">
                        {/* <p className="text-xl mb-2 font-bold ">Available Sets</p> */}
                        <div className="tabs">
                            <a className="tab tab-lifted tab-disabled">Recommended</a>
                            <a className="tab tab-lifted tab-active">My Sets</a>
                        </div>
                    </div>
                </div>
                {(filteredSetsData.length !== 0 && filteredSetsData[0] === "loading") && <LoadingComponent />}
                <div className="grid grid-cols-3 gap-3 max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-3 max-xl:grid-cols-3">
                    {filteredSetsData.length === 0 && <h1>No set found</h1>}
                    {filteredSetsData[0] !== "loading" &&
                        filteredSetsData.length > 0 &&
                        filteredSetsData.map((set, key) => {
                            let badgeClass = getRandomBadgeClass();
                            return (
                                <div
                                    key={key}
                                    className="card w-98 bg-base-100 shadow-xl !rounded-lg"
                                >
                                    <div className="card-body">
                                        <div className="flex grid-cols-2">
                                            <div className="grow truncate ">
                                                <div className="tooltip" data-tip={set.set_name}>
                                                    <div className="avatar">
                                                        <div className="!w-7 !h-7 rounded-full overflow-hidden block">
                                                            <img
                                                                src={
                                                                    config.logo_url ||
                                                                    "https://image.bigbooster.in/e2e217b9-ddd7-4ef8-8ceb-f01125c3d482.png"
                                                                }
                                                            />
                                                        </div>
                                                        <span className="card-title truncate ml-4 -mt-[2px] !text-2xl c-font1 block truncate">
                                            {set.set_name}
                                        </span>
                                                    </div>

                                                    {/* <h2 className="card-title ml-4 !text-2xl c-font1 block tooltip"  data-tip={set.set_name}>
                                <span className="truncate block">{set.set_name}</span>
                                </h2> */}

                                                    {/* <button className="btn">Hover me</button> */}
                                                </div>
                                            </div>
                                            <div className="flex-none w-24 text-right">
                                                {/*//editor*/}
                                                <button  onClick={(e)=>navigateToPage(e, "/dashboard/edit", set)} className="btn btn-sm  btn-outline  c-font1  capitalize font-bold rounded-[5px]  truncate">
                                                    <AiFillEdit/> Edit
                                                </button>
                                            </div>
                                        </div>

                                        {/*<div className="card-actions justify-end">*/}
                                        {/*    */}
                                        {/*</div>*/}

                                        {/* <div className="tooltip" data-tip={set.set_name}>
                            <h2 className="card-title ml-4 !text-2xl c-font1 truncate block">{set.set_name}</h2>
                            </div> */}
                                        {/* </div> */}
                                        <div className="ml-12">
                        <span
                            className={`badge ${badgeClass} border-none badge-sm p-2 font-medium font-serif `}
                        >
                          {set.questions_selected} Ques.
                        </span>
                                            <span
                                                className={`badge ${badgeClass} border-none badge-sm p-2 font-medium font-serif ml-2`}
                                            >
                          {set.created_date}
                        </span>
                                        </div>
                                        <div className="divider m-0"></div>
                                        <div className="card-actions ">
                                            <div className="grid  grid-cols-3 gap-3 place-content-stretch">
                                                <button className="btn btn-outline btn-sm btn-primary c-font1  capitalize font-bold rounded-[5px] m-1 truncate"
                                                        onClick={(e)=>navigateToPage(e, "/pdf", set)}
                                                >
                                                    Access PDF
                                                </button>
                                                <button className="btn btn-primary btn-sm c-font1  capitalize font-bold rounded-[5px] m-1 truncate"
                                                        onClick={(e)=>navigateToPage(e, "/ppt", set)}
                                                >
                                                    Access PPT
                                                </button>
                                                
                                                <a href={"https://form.utkarsh.com/uploads/2023/question-ppt.php?type=teachappset&setid="+set.set_id+"&set_password="+set.set_password} target="_blank">
                                                    <button className="btn btn-warning btn-sm c-font1  capitalize font-bold rounded-[5px] m-1 truncate">
                                                        Panel Pdf
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>



        </>
    );
}
export default DashboardPage;

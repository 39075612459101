import {useState, useRef} from "react";
import {RxCross2} from "react-icons/rx";
import { useNavigate, useLocation } from "react-router-dom";

const GeneratePdfHome=()=>{

    const [id,setId]=useState();
    const [psw,setPsw]=useState();
    const [endpoint,setEndpoint]=useState();
    const closeBtnRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const generatePdf=  async (e)=>{
        e.preventDefault();

        closeBtnRef.current.click();
        navigate('/ppt', { state: {data:{ set_id: id, set_password: psw, endpoint: endpoint }, path:location.pathname}});
    }

    return(
        <>
            {/*<label htmlFor="my-modal" className="btn">open modal</label>*/}

            <input type="checkbox" id="genPdf" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box  !rounded-sm">
                    <form className="text-center" onSubmit={generatePdf}>
                        <p className="text-2xl font-bold c-font1">Access PPT</p>
                        <div className="card-actions justify-end">
                            <label ref={closeBtnRef} htmlFor="genPdf" className="btn btn-ghost btn-sm mt-[-30px]">
                                <RxCross2/>
                            </label>
                        </div>
                        <input type="text" placeholder="Set Id" className="input rounded-[5px] input-bordered text-sm  w-full m-2  !rounded-sm c-font1"
                               value={id} onChange={(e)=>(setId(e.target.value))} required/>
                        <input type="password" placeholder="Password" className="input input-bordered text-sm rounded-[5px] w-full m-2  !rounded-sm c-font1"
                               value={psw} onChange={(e)=>(setPsw(e.target.value))} required/>
                        <input
                            type="text"
                            placeholder="end point... (optional)"
                            className="input input-bordered text-sm rounded-[5px] w-full m-2 !rounded-sm c-font1"
                            value={endpoint}
                            onChange={(e) => setEndpoint(e.target.value)}
                            />
                        {

                            !isLoading? <button type="submit" className="btn w-full m-2 !rounded-sm c-font1 normal-case">
                                Submit
                            </button>:<button type="submit" className="btn w-full m-2 !rounded-sm c-font1 normal-case loading btn-disabled" ></button>
                        }
                    </form>
                </div>
            </div>
        </>
    )
}
export default GeneratePdfHome;

import { timerSecondBox, jumpingBoxStyle, timerBoxAllElement } from "../Styles/style_variables";
export function TimerSecondBoxSuggestion(props) {
    return <div className="timeHelper" style={timerBoxAllElement}>
        <div style={{ display: "flex", }}>
            <span onClick={() => { props.setTimerDuration(10); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>10 sec</span>
            <span onClick={() => { props.setTimerDuration(15); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>15 sec</span>
            <span onClick={() => { props.setTimerDuration(20); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>20 sec</span>
        </div>

        <div style={{ display: "flex", }}>

            <span onClick={() => { props.setTimerDuration(25); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>25 sec</span>
            <span onClick={() => { props.setTimerDuration(30); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>30 sec</span>
            <span onClick={() => { props.setTimerDuration(35); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>35 sec</span>
        </div>
        <div style={{ display: "flex", }}>

            <span onClick={() => { props.setTimerDuration(40); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>40 sec</span>
            <span onClick={() => { props.setTimerDuration(45); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>45 sec</span>
            <span onClick={() => { props.setTimerDuration(60); props.setShowTimer(true); props.setShowSelectTimeBox(false); props.setKey(prevKey => prevKey + 1) }} style={timerSecondBox}>60 sec</span>
        </div>
    </div>
}
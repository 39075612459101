import parse from 'html-react-parser';
function Options(props) {
    //const concatenator = " • ";
    const concatenator = " / ";

    var option1_hin = props.option1_hin;
    var option2_hin = props.option2_hin;
    var option3_hin = props.option3_hin;
    var option4_hin = props.option4_hin;
    var option5_hin = props.option5_hin;


    var option1_eng;
    var option2_eng;
    var option3_eng;
    var option4_eng;
    var option5_eng;
    var itemOne, itemTwo, itemThree, itemFour, itemFive;
    if (props.option1_eng != false) { // it means we have billingual content
        option1_eng = props.option1_eng;
        option2_eng = props.option2_eng;
        option3_eng = props.option3_eng;
        option4_eng = props.option4_eng;
        option5_eng = props.option5_eng;

        // if options are same of both hindi and english ... then then join with empty string else with english string
        option1_hin += option1_hin == option1_eng ? "" : concatenator + option1_eng;
        option2_hin += option2_hin == option2_eng ? "" : concatenator + option2_eng;
        option3_hin += option3_hin == option3_eng ? "" : concatenator + option3_eng;
        option4_hin += option4_hin == option4_eng ? "" : concatenator + option4_eng;
        option5_hin += option4_hin == option5_eng ? "" : concatenator + option5_eng;

    }

    itemOne = parse(option1_hin);
    itemTwo = parse(option2_hin)
    itemThree = parse(option3_hin)
    itemFour = parse(option4_hin)
    if (option5_hin != "" && option5_hin != concatenator) {
        itemFive = parse(option5_hin)
    }

    if (itemOne.props != undefined) {
        itemOne = itemOne.props?.children?.toString() || "";
    } else {
        if (Array.isArray(itemOne)) {
            itemOne = itemOne.map((reactComponent) => {
                return reactComponent.props != undefined ? reactComponent.props?.children?.toString() || "" : reactComponent;
            }).toString();
        }
    }

    if (itemTwo.props != undefined) {
        itemTwo = itemTwo.props?.children?.toString() || ""
    } else {
        if (Array.isArray(itemTwo)) {
            itemTwo = itemTwo.map((reactComponent) => {
                return reactComponent.props != undefined ? reactComponent.props?.children?.toString() || "" : reactComponent;
            }).toString();
        }
    }

    if (itemThree.props != undefined) {
        itemThree = itemThree.props?.children?.toString() || ""
    } else {
        if (Array.isArray(itemThree)) {
            itemThree = itemThree.map((reactComponent) => {
                return reactComponent.props != undefined ? reactComponent.props?.children?.toString() || "" : reactComponent;
            }).toString();
        }
    }

    if (itemFour.props != undefined) {
        itemFour = itemFour.props?.children?.toString() || "";
    } else {
        if (Array.isArray(itemFour)) {
            itemFour = itemFour.map((reactComponent) => {
                return reactComponent.props != undefined ? reactComponent.props?.children?.toString() || "" : reactComponent;
            }).toString();
        }
    }

    if (itemFive != undefined) {
        if (itemFive.props != undefined) {
            itemFive = itemFive.props?.children?.toString() || "";
        } else {
            if (Array.isArray(itemFive)) {
                itemFive = itemFive.map((reactComponent) => {
                    return reactComponent.props != undefined ? reactComponent.props?.children?.toString() || "" : reactComponent;
                }).toString();
            }
        }
    }


    var arrOfOption = [itemOne, itemTwo, itemThree, itemFour];
    var checkOptionLength = Math.max(...(arrOfOption.map(el => el.length)));

    var optionInlineStyle = {
        fontSize: props.fontSize + "px",
        lineHeight: "22px",
    }

    var secondRowInlineStyle = {
    }
     
    checkOptionLength=30;
    if (checkOptionLength > 20) {
        optionInlineStyle.display = "block";
        secondRowInlineStyle.marginLeft = "12px";
        secondRowInlineStyle.width = "100%";
    }

    return (

        (checkOptionLength > 20) ? <div className="Options bg-transparent" style={optionInlineStyle} id={props.question_no + "_optionsDiv"}>
            <div className="firstRow" style={secondRowInlineStyle}>
                <div className="single_option" style={{ background: props.Answer == "A" ? "yellow" : "white", color: props.Answer == "A" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(a) &nbsp;</span>
                    <label > {parse(option1_hin)}  </label>
                </div>
                <div className="single_option" style={{ background: props.Answer == "B" ? "yellow" : "white", color: props.Answer == "B" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(b) &nbsp;</span>
                    <label > {parse(option2_hin)}</label>
                </div>
            </div>
            <div className="secondRow" style={secondRowInlineStyle}>
                <div className="single_option" style={{ background: props.Answer == "C" ? "yellow" : "white", color: props.Answer == "C" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(c) &nbsp;</span>
                    <label > {parse(option3_hin)} </label>
                </div>
                <div className="single_option" style={{ background: props.Answer == "D" ? "yellow" : "white", color: props.Answer == "D" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(d) &nbsp;</span>
                    <label > {parse(option4_hin)} </label>
                </div>
                {
                    (itemFive == "" || itemFive == undefined) ? false : <div className="single_option" style={{ background: props.Answer == "E" ? "yellow" : "white", color: props.Answer == "E" ? "blue" : "black" }}>
                        <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(e) &nbsp;</span>
                        <label > {parse(option5_hin)} </label>
                    </div>
                }
            </div>
        </div> : <div className="Options" style={optionInlineStyle} id={props.question_no + "_optionsDiv"}>
            <div className="firstRow" style={secondRowInlineStyle}>
                <div className="single_option" style={{ background: props.Answer == "A" ? "yellow" : "white", color: props.Answer == "A" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(a) &nbsp;</span>
                    <label > {parse(option1_hin)}  </label>
                </div>
                <div className="single_option" style={{ background: props.Answer == "C" ? "yellow" : "white", color: props.Answer == "C" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(c) &nbsp;</span>
                    <label > {parse(option3_hin)} </label>
                </div>
                {
                    (itemFive != "" || itemFive == undefined) ? false : <div className="single_option" style={{ background: props.Answer == "E" ? "yellow" : "white", color: props.Answer == "E" ? "blue" : "black" }}>
                        <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(e) &nbsp;</span>
                        <label > {parse(option5_hin)} </label>
                    </div>
                }
                {
                    (itemFive == "" || itemFive == undefined) ? false : <div className="single_option" style={{ background: props.Answer == "E" ? "yellow" : "white", color: props.Answer == "E" ? "blue" : "black" }}>
                        <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(e) &nbsp;</span>
                        <label > {parse(option5_hin)} </label>
                    </div>
                }
            </div>
            <div className="secondRow" style={secondRowInlineStyle}>
                <div className="single_option" style={{ background: props.Answer == "B" ? "yellow" : "white", color: props.Answer == "B" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(b) &nbsp;</span>
                    <label > {parse(option2_hin)}</label>
                </div>
                <div className="single_option" style={{ background: props.Answer == "D" ? "yellow" : "white", color: props.Answer == "D" ? "blue" : "black" }}>
                    <span className="optionName" style={{ fontSize: (props.fontSize - 1) + "px", }}>(d) &nbsp;</span>
                    <label > {parse(option4_hin)} </label>
                </div>

            </div>
        </div>

    );
}
export default Options;

import Swal from "sweetalert2";
import { updatePositionAPI } from './updatePositionAPI'

export function goToNextQuestion(question_no, questions, updatePositionConfig, questionHeight, questionWidth, questionXposition, questionYposition, optionHeight, optionWidth, optionXposition, optionYposition, optionCurrentFontSize, setQuestionNo, setQuestionXposition, setQuestionYposition, setQuestionHeight, setQuestionWidth, setOptionXposition, setOptionYposition, setOptionHeight, setOptionWidth, setQuestionFontSize, setOptionFontSize, checkAutoPlay = false) {
    if(updatePositionConfig) {
        var datatoUpdate = {
            "question_height": questionHeight,
            "question_width": questionWidth,
            "question_x_position": questionXposition,
            "question_y_position": questionYposition,
            "question_font_size": optionCurrentFontSize(),
            "option_height": optionHeight,
            "option_width": optionWidth,
            "option_x_position": optionXposition,
            "option_y_position": optionYposition,
            "option_font_size": optionCurrentFontSize(),
        }
        updatePositionAPI(questions[question_no].questionSource,questions[question_no].record_id, JSON.stringify(datatoUpdate));
        questions[question_no]['ppt_position_config'] = JSON.stringify(datatoUpdate);
    }
    let nextQuestionNo = question_no + 1;
    if (questions.length <= nextQuestionNo) {
        nextQuestionNo = 0
    }
    if (!checkAutoPlay) setQuestionNo(nextQuestionNo)
    setQuestionXposition(JSON.parse(questions[nextQuestionNo].ppt_position_config).question_x_position)
    setQuestionYposition(JSON.parse(questions[nextQuestionNo].ppt_position_config).question_y_position)
    setQuestionHeight(JSON.parse(questions[nextQuestionNo].ppt_position_config).question_height)
    setQuestionWidth(JSON.parse(questions[nextQuestionNo].ppt_position_config).question_width)
    setOptionXposition(JSON.parse(questions[nextQuestionNo].ppt_position_config).option_x_position)
    setOptionYposition(JSON.parse(questions[nextQuestionNo].ppt_position_config).option_y_position)
    setOptionHeight(JSON.parse(questions[nextQuestionNo].ppt_position_config).option_height)
    setOptionWidth(JSON.parse(questions[nextQuestionNo].ppt_position_config).option_width)
    return nextQuestionNo
}